@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,600');
body {
    font-family: 'Fira Sans', arial;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

#mcanvas {
    display: none;
}


/* This is to solve the safari issue of bootstrap  */

.row:before,
.row:after {
    display: none !important;
}


/* End of it */


/* Toast style */

.Toastify__toast-container {
    padding: 4px;
    width: 492px;
}

.Toastify__toast {
    width: 100%;
    color: #272727;
    margin-top: 11px;
    padding: 5px 12px;
    font-size: 13px;
    border-radius: 8.6px;
    min-height: 30px;
    box-shadow: none;
}

.Toastify__toast-body {
    padding: 0px !important;
}

.Toastify__toast--error {
    background: #ffcece;
    border: 0.8px solid #ff4040;
}

.Toastify__toast--success {
    background: #d7e7f7;
    border: 0.8px solid #7bb0f1;
}

.Toastify__close-button {
    display: none;
}

.wrapper {
    overflow: hidden;
}

a,
a:hover,
a:focus,
a:active,
.btn,
.btn:hover {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}


/* ScrollBar Styling */

 ::-webkit-scrollbar {
    width: 10px;
    background: #ccc;
    border-radius: 50px;
}

 ::-webkit-scrollbar-thumb {
    background: #21252940;
    border-radius: 50px;
}

.test-02::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.test-02::-webkit-scrollbar-thumb {
    background: transparent;
    /* border-radius: 50px; */
}

.nav>li>a {
    padding: 10px 15px 10px 0 !important;
}


/* Avatar classes */

.avatar-xs {
    height: 2rem;
    width: 2rem;
}

.avatar-sm {
    position: relative;
    height: 2.8rem;
    width: 2.8rem;
    z-index: 2;
}

.avatar-md {
    height: 4.5rem;
    width: 4.5rem;
}

.avatar-lg {
    height: 6rem;
    width: 6rem;
}

.avatar-xl {
    height: 7.5rem;
    width: 7.5rem;
}

.avatar-wrapper {
    display: inline;
}

.avatar-sm:nth-child(2) {
    position: relative;
    right: 15px;
    z-index: 1;
}

.avatar-sm:nth-child(3) {
    position: relative;
    right: 25px;
    z-index: 0;
}

.player_logo {
    width: 25%;
    position: absolute;
    top: 20px;
    left: 20px;
}

.fullscreen_player_logo {
    width: 25%;
    position: absolute;
    top: 60px;
    left: 20px;
}


/* Large Radio btns */

.bigradio {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    border: 4px solid #ccc;
    border-top-color: #bbb;
    border-left-color: #bbb;
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.rc-anchor-error-msg-container {
    display: none !important;
}

.captcha-wrapper .rc-anchor-error-msg {
    font-size: 11px !important;
    margin: 6px 4px !important;
    top: 6px !important;
}

.bigradio:checked {
    border: 8px solid #7bb0f1;
}


/* Helper Classes */

.full-width {
    width: 100%;
}

.flex-cont {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.name-text {
    font-weight: 800;
    text-transform: capitalize;
}

.invisible {
    display: none !important;
}

.hidden {
    visibility: none !important;
}

small {
    font-size: 12px;
}

.shrinked_40 {
    width: 40%;
}

.cap {
    text-transform: capitalize;
}

.no-cap {
    text-transform: lowercase;
}

.billdesk_popup {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
}

.popup-txt-p {
    width: 65%;
}

.popup_logo {
    width: 40% !important;
}

.saved-toast {
    width: 30%;
    margin: 0 auto;
    padding: 8px 20px;
    position: absolute;
    top: 5%;
    font-size: 16px;
    background-color: #fff;
    border-radius: 10px;
}

.logout-btn-desktop {
    float: right;
    margin-right: 30px;
    position: relative;
    top: 15px;
    font-size: 16px;
    color: #7bb0f1;
    font-weight: 800;
    letter-spacing: 1.1px;
    cursor: pointer;
}

.logout-grp-desktop {
    float: right;
    margin-right: 15px;
    position: absolute;
    top: 3%;
    font-size: 16px;
    right: 30px;
    color: #7bb0f1;
    font-weight: 800;
    letter-spacing: 1.1px;
    cursor: pointer;
}

.schedules-span {
    position: relative;
    bottom: -4px;
    letter-spacing: 0.02px;
    font-size: 15px;
}

.bottom-2 {
    position: relative;
    bottom: -3px;
}

.opacity {
    opacity: 1;
}

.light {
    opacity: 0 !important;
}

.text-low {
    color: #999999;
}

.text-extra-low {
    color: #d7d7d7;
}

.text-center {
    text-align: center;
}

.mar-tb-3 {
    margin: 3% 0 !important;
}

.mb-t-45 {
    margin: 45px 0 15px 0;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.f-w-b {
    font-weight: 900;
}

.padbox {
    padding: 20px 0px 0 10px;
}

.pl-10 {
    padding-left: 10px;
}

.no-pad-t {
    padding-top: 0px !important;
}

.pb-10 {
    padding-bottom: 10px;
}

.no-mar {
    margin: 0 !important;
}

.m-1 {
    margin: 1% !important;
}

.m-2 {
    margin: 2% !important;
}

.mt-15-pc {
    margin-top: 15% !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-35 {
    margin-top: 35px;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.m-tb-12 {
    margin: 12px 0;
}

.m-tb-25 {
    margin: 25px 0;
}

.mt3-b2 {
    margin-top: 3%;
    margin-bottom: 2%;
}

.m-b-4 {
    margin-bottom: 4% !important;
}

.m-b-3 {
    margin-bottom: 3% !important;
}

.m-b-2 {
    margin-bottom: 2% !important;
}

.mb-1 {
    margin-bottom: 2px;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.m-r-5 {
    margin-right: 5% !important;
}

.m-rl-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.m-l-0 {
    margin-left: 0px !important;
}

.ml-2 {
    margin-left: 2px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-l-40 {
    margin-left: 25%;
}

.mr-8 {
    margin-right: 8px;
}

.mr-10 {
    margin-right: 10px !important;
}

.l-h {
    line-height: 1.39 !important;
}

.f-w-b {
    font-weight: bold !important;
}

.f-14 {
    font-size: 14px !important;
}

.f-16 {
    font-size: 16px !important;
}

.f-18 {
    font-size: 18px !important;
}

.f-20 {
    font-size: 20px !important;
}

.f-28 {
    font-size: 28px !important;
}

.f-36 {
    font-size: 36px;
}

.fl-r {
    float: right !important;
}

.pointer-events-none {
    pointer-events: none;
}

.select-cursor-hover {
    cursor: pointer;
}

.select-cursor-disable {
    cursor: not-allowed;
}

.show-on-mobile {
    display: none;
}

.non-mobile-view {
    display: none;
}

.red-text {
    color: red;
}

.black-text {
    color: #000 !important;
}

.no-border {
    border: none !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.timer-reset-btn-wrapper {
    position: absolute;
    bottom: 30px;
    width: 100%;
    z-index: 200;
}

.timer-reset-btn-wrapper p {
    text-align: center;
}

.timer-reset-btn-wrapper>p>button {
    width: 25%;
    padding: 5px 10px;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.8px !important;
    border-radius: 25px;
    border-color: #7bb0f1;
    background-color: transparent;
    outline: none;
    box-shadow: none;
}

.fixedTimerWidth {
    display: inline-block;
    width: 13px;
    margin-bottom: 0;
}

.added-border-bottom {
    border-bottom: 3.25px solid #E3E3E3;
}

.top-position {
    top: 85px !important;
}

button:focus {
    outline: none;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: transparent;
}

.padded-btm {
    padding-bottom: 10px;
    border-bottom: 2px solid #d8d8d8 !important;
}

.padded-btm-light {
    padding-bottom: 10px;
    border-bottom: 3px solid #eee !important;
}

.upcoming-recording-accord {
    padding: 0px !important;
}

.upcoming-recording-accord>li>a.acco-btn.plains {
    padding: 27px 15px!important;
    margin-bottom: 25px;
}

.popup-info-text {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.39px;
    text-align: center;
    color: #272727;
}

.big-popup {
    max-width: 740px;
    min-width: 670px;
}

.font-13 {
    font-size: 13px;
}

.breadcrum-text {
    margin-bottom: 0;
    font-size: 16px;
    margin-left: 5%;
    position: relative;
    bottom: 20px;
    color: #999999;
}

.empty-sidebar {
    padding-top: 10%;
    text-align: center;
}

.empty-sidebar>p {
    font-weight: 900;
    margin-top: 10%;
}

.bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.btn {
    font-size: 1em;
}

.btn-icon-24 {
    width: 24px;
    height: 24px;
}

.breathe {
    padding-top: 4em;
    padding-bottom: 4em;
}

.low-breathe {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.mid-breathe {
    padding-top: 3.2em;
    padding-bottom: 1.5em;
}

.clearfix {
    clear: both;
}

.alert-count {
    width: 20px;
    height: 20px;
    line-height: 1.9;
    /* padding: 0.24pc 0.2pc; */
    border-radius: 50%;
    position: absolute;
    bottom: 20px;
    left: 60px;
    background-color: #fc7866;
    font-size: 60%;
}

.badge-padding {
    padding: 0.25em 0.4em !important;
}

.active>.alert-count {
    border-radius: 50%;
    position: absolute;
    bottom: 20px;
    left: 95px;
    background-color: #fc7866;
}

.main h2 {
    font-size: 28px;
    margin-bottom: 15px;
}

.upload-photo-dropdown {
    transform: translate3d(-30px, -45px, 0px) !important;
    padding: 15px 10px;
    border-radius: 8px;
    box-shadow: 0 2px 9px -2px rgba(0, 0, 0, 0.5);
    border: none;
    border-top-left-radius: 0;
}

.upload-photo-dropdown>button {
    font-size: 16px !important;
    font-weight: 500;
    color: #000;
}

.upload-photo-dropdown>button:active {
    color: #000;
}

.upload-photo-dropdown>button:hover {
    background-color: transparent;
}

.capture-pic-window>.popup-overlay>.popup-box {
    max-width: 100%;
    width: fit-content;
    background-color: transparent;
}

.capture-actionables {
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    text-align: center;
}

.retake-btn {
    font-size: 16px !important;
    font-weight: 500!important;
    color: #000 !important;
}

.capture-pic-window>.popup-overlay>.popup-box>.closePopup {
    top: 55px;
    right: 55px;
}

.greyscaleFilter {
    filter: grayscale(100%);
}

.main h4,
.profile-main h4 {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: normal;
}

.device-page-container h3 {
    margin-bottom: 30px;
    font-size: 24px;
}

.top-bar {
    position: relative;
    padding: 30px;
}

.top-bar .logo img {
    max-height: 70px;
}

.btn.btn-success {
    border-radius: 60px;
    background-color: #7BB0F1;
    border: 0px;
    padding: 10px 53px;
    font-size: 18px;
    font-weight: 600;
    color: #FFF;
    letter-spacing: 0.5px;
}

.blue-link {
    font-size: 16px;
    color: #7bb0f1;
    font-weight: bold;
    cursor: pointer;
    z-index: 999;
}

.checkbox-text {
    display: inline-block;
    /* height: 32px; */
    /* padding-left: 40px; */
    font-size: 16px;
    color: #999;
    position: relative;
    line-height: 32px;
    bottom: 10px;
}

.blue-link:focus,
.blue-link:hover {
    font-size: 16px;
    color: #7bb0f1;
    font-weight: bold;
    cursor: pointer;
    z-index: 999;
}

.btn.btn-long {
    padding: 9px 100px!important;
}

.intro-slider .slick-item {
    text-align: center;
}

.intro-slider .slick-item .image {
    width: 100%;
    max-width: 600px;
    height: 350px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-slider .slick-item .image img {
    max-height: 100%;
}

.intro-slider .slick-item h3 {
    font-size: 24px;
    margin-bottom: 30px;
}

.slick-slide,
.slick-slide * {
    outline: none !important;
}

ul.slick-dots {
    list-style: none;
    padding: 0;
    text-align: center;
}

ul.slick-dots li {
    display: inline-block;
    margin: 0px 4px;
    width: auto;
}

ul.slick-dots li button {
    width: 8px;
    height: 8px;
    padding: 0px;
    background-color: #E9E9E9;
    border-radius: 10px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    outline: none;
    border: 0px;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

ul.slick-dots li button:before {
    content: none;
}

ul.slick-dots li.slick-active button {
    width: 32px;
    background-color: #7BB0F1;
}

.sepr {
    position: relative;
    font-size: 18px;
    color: #999;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.sepr:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 1px;
    width: 40%;
    background-color: #979797;
    left: 0px;
}

.sepr:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 1px;
    width: 40%;
    background-color: #979797;
    right: 0px;
}

.accordian-centered-text {
    margin-bottom: 0px;
    position: relative;
    top: 15%;
}

.onboarding .form-panel {
    max-width: 408px;
    margin: 0 auto;
}

.onboarding-img img {
    padding: 80px 35px;
}

.form-panel {
    border: 1px solid #D7D7D7;
    border-radius: 21.5px;
    padding: 32px;
    font-size: 16px;
}

.form-panel h3 {
    color: #272727;
    font-size: 18px;
    margin-bottom: 8px;
}

.form-panel h4 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 0.4px;
    color: #999999;
}

.form-panel .backArrow {
    margin-bottom: 24px;
}

.form-panel .form-group {
    margin-bottom: 15px;
    /* margin-bottom: 63%; */
}

.form-panel .form-control {
    border: 1px solid #D7D7D7;
    box-shadow: none;
    padding: 8px;
    font-size: 18px;
    height: 48px;
    border-radius: 7.7px;
}

.form-panel .social-buttons {
    position: relative;
    /* display: flex;
        flex-direction: column; */
}

.form-panel .social-buttons .btn.btn-social {
    padding-bottom: 2px;
    background-color: #FFF;
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    box-shadow: 0 0 7px 0 rgba(39, 39, 39, 0.19);
    border-radius: 7.9px;
    line-height: 38px;
    outline: none;
}

.form-panel .social-buttons .btn.btn-social.black {
    background-color: #000;
    color: #fff;
}

.form-panel .social-buttons .btn.btn-social img {
    max-height: 40px;
    float: left;
    margin-top: -1px;
    margin-right: 10px;
    margin-left: 5px;
}

.form-panel .btn.btn-success,
.form-panel .btn.btn-info {
    width: 100%;
    margin-top: 19px;
    margin-bottom: 15px;
    z-index: 9;
}

.form-panel .btn.btn-info.cnt {
    width: fit-content;
    margin-top: 0;
    margin-bottom: 0;
}

.form-panel a {
    display: inline-block;
    color: #7BB0F1!important;
    font-weight: 600;
    font-stretch: normal;
    letter-spacing: 0.34px;
    text-align: justify;
}

.slide-pan {
    text-align: center;
    margin-bottom: 0px;
    color: #999;
}

.slide-pan .image {
    width: 100%;
    max-width: 600px;
    height: 350px;
    margin: 0px auto 25px auto;
    text-align: center;
}

.slide-pan .image img {
    max-height: 100%;
    width: auto;
    margin: 0 auto;
}

.notes {
    font-size: 14px;
    margin-bottom: 0px;
}

.password-notes {
    background-color: #FFF9EB;
    padding: 15px;
    font-size: 13px;
    margin-bottom: 10px;
}

.password-notes ul {
    padding-left: 0px;
    list-style: none;
}

.password-notes ul li {
    padding-left: 30px;
    line-height: 25px;
    position: relative;
}

.password-notes ul li:before {
    content: "";
    position: absolute;
    left: 3px;
    top: 4px;
    width: 16px;
    height: 16px;
    border: 1px solid #999;
    border-radius: 13px;
}

.password-notes ul li.valid:before {
    border: 0px;
    background-image: url(../images/tick.png);
}

.open-eye {
    position: absolute;
    width: 8%;
    top: 30%;
    right: 3%;
    filter: grayscale(1);
}

.close-eye {
    position: absolute;
    width: 8%;
    top: 40%;
    right: 3%;
    filter: grayscale(1);
}

.slide-pan h3 {
    font-size: 24px;
    color: #272727;
}

.rw-select-bordered {
    border: none;
}

.rw-select-bordered:hover,
.rw-select-bordered:active {
    background-color: transparent;
    box-shadow: none;
}

.success {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../images/bg01.png);
}

.bottom-actions {
    padding: 100px 35px;
}

.bottom-fixed {
    position: absolute;
    width: 71%;
    bottom: 32px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.btm-margin {
    margin-bottom: 30px!important;
}

button.btn:disabled,
button.btn:disabled:hover {
    background: #D7D7D7;
}

.highlighted {
    color: #7BB0F1;
    font-weight: bold;
    font-size: 16px;
    margin: 25px auto;
}

.highlighted-grey {
    color: #999;
    font-size: 14px;
    font-weight: 800 !important;
    letter-spacing: 0.3px;
}

.list-permissions .checkmarker {
    float: left;
    width: 33%;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.78);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-overlay .popup-box {
    width: 100%;
    max-width: 540px;
    background-color: #FFF;
    position: relative;
    padding: 35px;
    border-radius: 24px;
    text-align: center;
}

.popup-overlay .popup-box.compact {
    max-width: 480px!important;
}

.popup-overlay .popup-box.lg {
    max-width: 800px;
}

.popup-overlay a.closePopup {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 502;
}

.popup-component {
    padding: 15px;
}

.popup-component h3 {
    margin: 15px auto 10px auto;
}

.popup-box h3 {
    margin: 16px auto;
    font-size: 24px;
    font-weight: bold;
}

.popup-box h3.norml {
    font-weight: 500!important;
}

.input-buffer {
    padding: 10px 0px 15px 0px;
}

.input-grouped {
    padding: 0px 0px 5px 0px;
}

.input-prepand {
    float: left;
    width: 85px;
    float: left;
    margin-right: 10px;
}

.flex-center {
    display: flex !important;
    justify-content: center !important;
    margin: 15px 0 !important;
}

.flex-center .profile-pic {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
}

.apple-log {
    font-size: 12px;
    color: #999;
}

.input-holder {
    margin-left: 100px;
}

ul.listview {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.listview.popupDevices {
    float: left;
    min-width: 182px;
}

h4.formTitle {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
    clear: both
}

h4.formSubTitle {
    font-size: 16px;
    margin-bottom: 20px;
    color: #272727;
    clear: both;
    line-height: 32px;
}

.noclear {
    clear: inherit!important;
}

ul.weekdays {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.weekdays li {
    display: inline-block;
}

ul.weekdays li:not(:last-child) {
    margin-right: 25px;
}

.toggle-box {
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    color: #999;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.toggle-box:active,
.toggle-box:focus {
    color: #999;
}

.not-selectable {
    background-color: #e8e8e8 !important;
}

.toggle-box:hover {
    color: #7BB0F1;
}

.toggle-box.off:hover {
    color: #999 !important;
}

.toggle-box.on {
    background-color: #D7E7F7;
    color: #7BB0F1;
    font-weight: bold;
}

.toggle-btn {
    display: inline-block;
    padding: 7px 20px;
    margin: 0px 20px 10px 0px;
    border-radius: 50px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    background-color: #FFF;
    color: #999;
    font-size: 16px;
}

.toggle-btn:hover {
    color: #7BB0F1;
}

.toggle-btn.on {
    background-color: #D7E7F7;
    color: #7BB0F1;
    font-weight: bold;
}

.float-container {
    text-align: left;
    border: solid 1px #999;
    border-radius: 10px;
    padding: 0px 15px;
    position: relative;
    height: 48px;
}

.rw-list {
    max-height: 300px;
}

.styleddropdown>.rw-widget {
    height: 100%;
}

.styleddropdown>.rw-widget>.rw-widget-container {
    border: none;
    height: 100%;
}


/* .searchDropdown>.rw-widget>.rw-popup-container .rw-popup {
    max-height: 300px;
} */

.searchDropdown>.rw-widget .rw-filter-input .rw-select {
    position: relative;
    bottom: 2px;
}

.searchDropdown>.rw-widget>.rw-popup-container .rw-filter-input {
    /* bottom: 45px;
    box-shadow: none; */
    border: 1px solid #ccc !important;
}


/* .searchDropdown>.rw-widget>.rw-popup-container .rw-list {
    position: relative;
    bottom: 45px;
} */

.styleddropdown>.rw-widget>.rw-popup-container {
    position: absolute;
    z-index: 1005;
    top: 102%;
    left: -22px;
    right: -6px;
    /* padding: 10px 0; */
    width: 352px;
    border: none;
}

.dualGrid>.styleddropdown>.rw-widget>.rw-popup-container {
    position: absolute;
    z-index: 1005;
    top: 102%;
    left: -20px;
    right: -6px;
    /* padding: 10px 0; */
    width: 390px;
    border: none;
}

.styleddropdown.sensitivity>.rw-widget>.rw-popup-container {
    position: absolute;
    z-index: 1005;
    top: 102%;
    left: -20px;
    right: -6px;
    /* padding: 10px 0; */
    width: 360px;
    border: none;
}

.fixed-width {
    width: 340px;
}

.fixed-min-width {
    display: inline-block;
    width: 45px;
}

.styleddropdown-two>.rw-widget>.rw-popup-container {
    position: absolute;
    z-index: 1005;
    top: 102%;
    left: -21px;
    right: -6px;
    /* padding: 10px 0; */
    width: 112%;
    border: none;
}

.styleddropdown>.rw-widget>.rw-popup-container>.rw-popup-transition>.rw-popup {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.styleddropdown>.rw-widget>.rw-popup-container>.rw-popup-transition>.rw-popup>ul>li {
    padding: 15px 20px;
    color: #999;
}

.styleddropdown>.rw-widget>.rw-popup-container>.rw-popup-transition>.rw-popup>ul>.rw-state-focus {
    border-color: transparent;
    border: none;
}

.styleddropdown>.rw-widget>.rw-popup-container>.rw-popup-transition>.rw-popup>ul>.rw-list-option.rw-state-selected {
    background-color: transparent;
    /* color: #333; */
    border: none;
}

.styleddropdown>.rw-widget>.rw-popup-container>.rw-popup-transition>.rw-popup>ul>.rw-list-option:hover {
    background-color: transparent;
    border-color: transparent;
}

.styleddropdown>.rw-widget>.rw-widget-input>.rw-dropdown-list-input {
    padding-top: 2px;
    font-size: 16px;
}

.styleddropdown label {
    z-index: 10 !important;
}


/* Loader CSS */

.loader {
    margin: 100px auto;
    width: 10px;
    height: 10px;
    position: relative;
    top: 40%;
    border-radius: 50%;
    color: #000;
    font-size: 10px;
    text-indent: -999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes load4 {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes load4 {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}


/* Loader CSS */

.loading-screen {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #ffffff8f;
    color: #000;
    z-index: 9999;
    /* left: -48%; */
    top: 0;
    overflow: hidden;
}

.loading-screen>p {
    text-align: center;
    top: 50%;
    position: relative;
}

.tab-loader {
    position: fixed;
    height: 100%;
    background-color: #ffffffed;
    color: #000;
    z-index: 9999;
    top: 8%;
    overflow: hidden;
}

.left-32 {
    left: 32%;
}

.left-25 {
    left: 25%;
}

.tab-loader>p {
    text-align: center;
    top: 50%;
    position: relative;
}

.float-container.wrapper-only {
    border-width: 0px!important;
    border-color: transparent;
    padding: 0px;
}

.float-container.select select {
    background-color: transparent;
    border: 0px;
    height: 46px;
    width: 100%;
    outline: none;
    font-size: 18px;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
}

.float-container.select select option {
    font-size: 15px;
}

.float-container.select select>.hidden-options {
    display: none;
}

.float-container input {
    border: none;
    outline: 0;
    font-size: 18px;
    line-height: 44px;
    background-color: transparent;
    width: 100%;
}

.float-container label {
    color: #999;
    font-weight: normal;
    transform-origin: top left;
    transition: all 300ms ease-in-out;
    font-size: 16px;
    position: absolute;
    transform: translate(0, 12px) scale(1);
    z-index: -1;
}

.float-container.active label {
    transform: translate(0, -10px) scale(.75);
    background-color: #FFF;
    z-index: 1;
    padding: 0px 15px 0px 8px;
}

.float-container .input-link {
    display: block;
    line-height: 48px;
    font-size: 16px;
}

.float-container .input-buttons {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 10px 15px;
    height: 48px;
}

.float-container .input-buttons h5 {
    font-size: 20px;
    color: #999;
    margin: 0px;
    line-height: 30px;
}

.float-container .input-buttons a {
    line-height: 30px;
    font-size: 16px;
    outline: none!important;
    color: #7BB0F1;
    font-weight: 600;
    letter-spacing: 0.34px;
}

.float-container.error {
    border-color: #CF4343;
}

.fontStyle,
.faq-body {
    font-size: 16px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.25; */
    letter-spacing: 0.34px;
    color: #2c2c2c;
}

.fontStyle a {
    color: #7bb0f1;
    text-decoration: underline;
}

.fontStyle>ul>li {
    line-height: 2;
}

.float-container.error label {
    color: #CF4343 !important;
}

.error-msg {
    position: relative;
    bottom: 25px;
    left: 20px;
    color: #cf4343;
}

.err-text {
    position: relative;
    bottom: 12px;
    color: #cf4343;
    font-size: 12px;
}

.grey-background {
    background-color: #eee !important;
    pointer-events: fill !important;
    cursor: not-allowed !important;
}

.float-container.valid {
    /* border: 1.4px solid #9FC576; */
}

.float-container.valid label {
    color: #999;
}

.float-container.wrapper-only label {
    left: 15px;
}

.float-container.wrapper-only .rw-widget-picker {
    height: 48px;
    border-radius: 7px;
}

.rw-state-focus .rw-widget-picker {
    border-color: #9FC576!important;
    box-shadow: none!important;
    outline: none;
}

.panel-holder {
    min-height: 350px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datepicker.default .datepicker-wheel {
    border-top-color: #CCC!important;
    border-bottom-color: #CCC!important;
}

.datepicker.default {
    background-color: #FFF!important;
    position: relative;
}

.datepicker-navbar,
.datepicker-header {
    display: none;
}

ul.listGroups {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 25px;
    margin-bottom: 25px;
}

ul.listGroups>li a.addGroup {
    font-weight: bold;
    color: #7BB0F1;
    font-size: 16px;
    border-bottom: 0px;
}

ul.listGroups>li a {
    position: relative;
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    clear: both;
    border-bottom: 2px solid #D8D8D8;
    min-height: 50px;
}

ul.listGroups>li a:not(.addGroup):not(.simple):before {
    content: "";
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 25px;
    height: 25px;
    background-image: url(../images/enter.png);
    background-size: 100%;
    opacity: 1
}

ul.listGroups>li a:hover {
    color: #7BB0F1;
}

ul.listGroups>li a:hover:before {
    opacity: 1
}

ul.listGroups>li h4 {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 16px;
}

ul.listGroups>li p {
    color: #272727;
    margin-bottom: 0px;
}

ul.notifications {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 25px;
    margin-bottom: 25px;
}

ul.notifications li {
    border-bottom: 0.25px solid #e4e4e4cc;
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-size: 15px;
}

ul.notifications li a {
    color: #7BB0F1;
    font-size: 16px;
}

ul.notifications li small {
    font-size: 13px;
    color: #999
}

ul.listItems {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 25px;
    margin-bottom: 25px;
}

ul.listItems>li {
    position: relative;
    display: block;
    clear: both;
    border-bottom: 2px solid #D8D8D8;
    padding-top: 15px;
    min-height: 50px;
}

ul.sharedWithList>li:first-of-type {
    padding-top: 15px !important;
}

ul.listItems>li:first-of-type {
    padding-top: 0px;
}

ul.listItems>li.have-avatar {
    padding-left: 50px;
}

ul.listItems>li.have-avatar .avatar {
    position: absolute;
    background-color: #CCC;
    width: 30px;
    height: 30px;
    top: 10px;
    left: 5px;
    border-radius: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

ul.listItems>li.thin {
    border-bottom: 0.25px solid #E3E3E3;
}

ul.listItems>li:last-child {
    border-bottom: 0px solid #D8D8D8;
}

.share-form-component>div>ul.listItems {
    padding-bottom: 5px;
    border-bottom: 4px solid #f5f5f5;
}

ul.listItems>li a.addItem {
    display: block;
    font-weight: bold;
    color: #7BB0F1;
    font-size: 16px;
    border-bottom: 0px;
    padding-bottom: 25px;
}

ul.listItems>li h4 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 4px;
}

ul.listItems>li p {
    color: #999;
}

.shared-devices-listing p {
    font-size: 16px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #f5f5f5;
}

.shared-devices-listing p:first-of-type {
    margin-bottom: 10px;
    border: none;
}

.shared-devices-listing p:last-of-type {
    padding-bottom: 0px;
    border: none;
}

.shared-devices-listing {
    border-bottom: 5px solid #f5f5f5;
}

.schedule-details {
    margin: 20px 0;
}

.schedule-details span {
    font-size: 14px;
    color: #999999;
}

.schedule-details>p {
    font-size: 16px;
    margin-bottom: 20px;
}

.schedule-details p {
    margin-bottom: 0px;
    font-size: 16px;
    letter-spacing: 0.34px;
    text-align: justify;
    color: #272727;
}

.schedule-details>div {
    padding: 15px 0;
    border-bottom: 1px solid #f5f5f5;
}

.dnd-quickactions-section>div:first-of-type {
    display: grid;
    grid-template-columns: 92% 8% 100%;
    padding: 25px 5px;
}

.dnd-quickactions-section>.info-note {
    font-size: 18px;
    text-align: center;
}

.security-mode-section>div:first-of-type {
    display: grid;
    grid-template-columns: 92% 8% 100%;
    padding: 25px 5px;
}

.security-mode-section>.info-note {
    padding: 2% 0 2% 3%;
    font-size: 18px;
}

.rightActions {
    float: right;
    text-align: right;
}

.rightActions button,
.rightActions a {
    border: 0px;
    background-color: transparent;
    /* padding: 3px 15px; */
    padding: 3px 8px;
}

.rightActions div {
    display: inline-block;
}

.right-icon-padding {
    padding: 4px 4px;
}

.back-arrow {
    position: relative;
    right: 10px;
    bottom: 10px;
}

.back-button>h3>img {
    margin-right: 8px;
}

.skip-btn {
    position: relative;
    bottom: 25px;
    font-size: 18px;
    line-height: normal;
    letter-spacing: 0.39px;
}

.offline-info-txt>span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #d7d7d7;
}

.offline-info-txt>a {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.02px;
    color: #7bb0f1;
}

.reset-note {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #999999;
}


/* DASHBOARD CLASSES */

h5 {
    font-size: 16px;
    color: #272727;
    font-weight: normal;
    letter-spacing: 0.34px;
}

header {
    position: fixed;
    width: 100%;
    padding: 15px;
    top: 0;
    left: 0;
    z-index: 500;
    background-color: #fff;
}

header .logo {
    padding: 10px 0px 10px 40px;
    float: left;
    width: 300px;
}

header .logo img {
    max-height: 50px;
}

header.scrolled {
    background-color: #FFF;
}

header .nav-buttons {
    padding: 5px;
    float: left;
}

header .nav-buttons ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 40px;
}

header .nav-buttons ul li {
    display: inline;
    margin-right: 15px;
}

header .nav-buttons ul li a {
    display: inline-block;
    line-height: 15px;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 25px;
    color: #333;
}

header .nav-buttons ul li a.active {
    display: inline-block;
    background-color: #333;
    line-height: 15px;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 25px;
    color: #FFF;
}

header .nav-buttons ul li a img {
    float: left;
    margin-right: 10px;
    max-height: 17px;
    display: none;
}

header .nav-buttons ul li a.active img {
    display: inline-block;
}

.aside-group>h4 {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 1.2rem;
    color: #999;
    letter-spacing: 0.34px;
}

.group-recording>.tab-content>.group-aside>h4 {
    /* font-size: 16px;
    font-weight: 900;
    margin-bottom: 1.2rem; */
    color: #000;
    margin-bottom: 20px;
}

.see-all {
    position: relative;
    bottom: 15px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.02px;
    color: #7bb0f1;
    cursor: pointer;
}

.notification-status-online {
    display: inline-block;
    margin: 4px 0;
    padding: 5px 12px;
    font-size: 10px;
    font-weight: 900;
    color: #9FC576;
    background-color: #E5F4EA;
    border-radius: 10px;
    line-height: 1;
    letter-spacing: 1px;
}

.notification-status-offline {
    display: inline-block;
    margin: 4px 0;
    padding: 5px 12px;
    font-size: 10px;
    font-weight: 900;
    color: #ac3e3e;
    background-color: #ffc8d0;
    border-radius: 10px;
    line-height: 1;
    letter-spacing: 1px;
}

.aside {
    position: fixed;
    z-index: 511;
    top: 0;
    right: 0;
    width: 340px;
    height: 100vh;
    overflow-y: auto;
    background-color: #FFF;
    padding: 100px 20px 0 20px;
    box-shadow: 0px -3px 8px #E3E3E3;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.aside>h5 {
    color: #999;
}

.aside.lg {
    width: 490px;
}

.sidebar {
    position: fixed;
    font-size: 1em;
    top: 0;
    left: 0;
    width: 200px;
    height: 100vh;
    overflow-y: hidden;
    padding: 90px 25px 20px 25px;
    z-index: 511;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}


/* .sidebar-nav { position: fixed; font-size: 1em; top: 0; left: 0; width: 450px; height: 100vh; overflow-x: visible; overflow-y: auto; background-color: #FFF; padding: 100px 0px 20px 0px; -webkit-transition: all 300ms; -moz-transition: all 300ms; -o-transition: all 300ms; transition: all 300ms; } */

.sidebar-nav {
    position: relative;
    font-size: 1em;
    top: 0;
    left: 0;
    overflow-x: visible;
    overflow-y: auto;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.sidebar-nav ul.sidebar-nav-buttons {
    list-style: none;
    margin: 0;
    padding: 0px;
    position: relative;
    overflow: visible;
}

.sidebar-nav ul.sidebar-nav-buttons li {
    display: block;
    padding-right: 7px;
    position: relative;
}

.sidebar-nav ul.sidebar-nav-buttons li a .icon {
    position: absolute;
    top: 0;
    left: 50px;
    bottom: 0;
    margin: auto 0;
    width: 42px;
    height: 42px;
    border-radius: 42px;
    background-color: #D7E7F7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-nav ul.sidebar-nav-buttons li a span {
    position: absolute;
    top: 23px;
    right: 35px;
    height: 25px;
    font-size: 23px;
}

.sidebar-nav ul.sidebar-nav-buttons li a span img {
    max-height: 25px;
    opacity: 0.6;
}

.sidebar-nav ul.sidebar-nav-buttons li a small {
    color: #999;
    font-size: 72%;
}

.sidebar-nav ul.sidebar-nav-buttons li a>p {
    width: 80%;
    margin-bottom: 0px;
    line-height: 18px;
}

.sidebar-nav ul.sidebar-nav-buttons li>a {
    font-size: 16px;
    padding-left: 120px;
    position: relative;
    display: block;
    height: 72px;
    /* padding-top: 18px; */
    line-height: 18px;
    display: flex;
    align-items: center;
}

.sidebar-nav ul.sidebar-nav-buttons li a:hover,
.sidebar-nav ul.sidebar-nav-buttons li.active a,
.sidebar-nav ul.sidebar-nav-buttons li a:focus {
    background-color: #F3F7FC;
}

.sidebar-nav ul.sidebar-nav-buttons li a:hover span img,
.sidebar-nav ul.sidebar-nav-buttons li.active a span img,
.sidebar-nav ul.sidebar-nav-buttons li a:focus span img {
    opacity: 1;
}

.sidebar-nav ul.sidebar-nav-buttons li.divider {
    padding-right: 8px;
}

.sidebar-nav ul.sidebar-nav-buttons li.divider hr {
    position: relative;
    border-top: 3px solid #F5F5F5;
    width: 100%;
}

.sidebar-style {
    display: block;
    margin-bottom: 25px;
    padding-right: 25px;
    padding-left: 60px;
    position: relative;
    font-size: 15px;
}

.sidebar-style .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    height: 42px;
    border-radius: 42px;
    background-color: #D7E7F7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-nav ul.sidebar-nav-buttons li:first-child a {
    border-top-right-radius: 24px;
}

.sidebar-nav ul.sidebar-nav-buttons li:last-child a {
    border-bottom-right-radius: 24px;
}

.sidebar ul li>a>span {
    font-size: 13px;
    line-height: 1;
}

.sidebar>.sidebar-body>h5 {
    color: #999;
    margin: 25px 0;
}

.sidebar>.sidebar-body>p {
    margin-top: 15px;
    font-size: 16px;
}

.accordions-list {
    position: relative;
    font-size: 1em;
    top: 0;
    left: 0;
    overflow-x: visible;
    overflow-y: auto;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.accordions-list ul.accordions {
    list-style: none;
    margin: 0;
    padding: 0px 0px 15px 0px;
    position: relative;
    overflow: visible;
}

.accordions-list ul.accordions>li {
    display: block;
    border-top: 3px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    height: 74px;
    overflow: hidden;
}

.profile-page-container>.accordions-list ul.accordions>li {
    height: 80px;
}

.accordions-list ul.accordions>li a.acco-btn .icon {
    position: absolute;
    top: 0;
    left: 30px;
    bottom: 5px;
    margin: auto 0;
    width: 42px;
    height: 42px;
    border-radius: 42px;
    background-color: #D7E7F7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordions-list ul.accordions>li a.acco-btn span {
    position: absolute;
    top: 21px;
    right: 25px;
    height: 25px;
    font-size: 23px;
}

.accordions-list ul.accordions>li a.acco-btn span img {
    max-height: 25px;
    opacity: 0.6;
    transform: rotate(90deg);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.accordions-list ul.accordions>li.active {
    height: auto;
}

.accordions-list ul.accordions>li.active>a.acco-btn span img {
    transform: rotate(-90deg);
}

.accordions-list ul.accordions>li a.acco-btn small {
    color: #999;
}

.accordions-list ul.accordions>li a.acco-btn {
    font-size: 16px;
    padding-left: 100px;
    position: relative;
    display: block;
    height: 80px;
    padding-top: 15px;
}

.accordions-list ul.accordions>li a.acco-btn:hover,
.accordions-list ul.accordions>li.active a.acco-btn,
.accordions-list ul.accordions>li a.acco-btn:focus {
    background-color: #F3F7FC;
}

.accordions-list .accordions-list ul.accordions>li>a.acco-btn.plain {
    padding-left: 0px!important;
}

span.pull-rite {
    position: absolute;
    right: 30px;
}

.accordions-list ul.accordions>li:last-child {
    border-top: 3px solid #f5f5f5;
    border-bottom: 0px;
}

.accordions-list ul.accordions>li:first-child {
    border-top: 4px solid #f5f5f5;
    /* border-bottom: 3px solid #eee; */
}

.accordions-list ul.accordions>li.active a.acco-btn.plain,
.accordions-list ul.accordions>li.active a.acco-btn.plain:active,
.accordions-list ul.accordions>li.active a.acco-btn.plain:focus {
    background-color: transparent;
}

.accordions-list ul.accordions>li a.acco-btn:hover span img,
.accordions-list ul.accordions>li.active a.acco-btn span img,
.accordions-list ul.accordions>li a.acco-btn:focus span img {
    opacity: 1;
}

.accordions-list ul.accordions>li a.acco-btn.plain {
    padding: 23px 15px!important;
}

.accordions-list ul.accordions>li a.acco-btn.plain p {
    margin-bottom: 0px;
}

.accordion-body {
    padding: 15px;
}

.accordion-body-sm {
    padding: 0 15px;
}

.device-input-wrapper {
    margin-bottom: 34px;
    text-align: center;
    clear: both;
}

.device-input-wrapper.noMargin {
    margin: 0px!important;
    padding: 15px 0px;
}


/* .my-device-box {
    width: 100%;
    text-align: center;
    margin-top: 40px;
    padding: 15px;
    position: relative;
    margin-bottom: 40px;
    min-height: 380px;
    border-radius: 18px;
    box-shadow: 0 0 6px 0 rgba(91, 91, 91, 0.29);
    background-color: #FFF;
} */


/* .my-device-box img {
    margin-bottom: 20px;
    max-height: 250px;
}

.my-device-box h3 {
    margin-top: 0px;
    margin-bottom: 10px;
}

.my-device-box h3.grayed {
    font-size: 22px;
} */


/* .grayed {
    color: #999!important;
}

.my-device-box p {
    color: #999;
    text-align: center;
}

.my-device-box p span {
    color: #000;
    display: block;
} */

.form-group .select-dropdown {
    position: absolute;
    right: 15px;
    top: 13px;
    pointer-events: none;
}


/* margin-bottom: 25px!important;
} */

.top-margin {
    margin-top: 20px!important;
}


/* margin-bottom: 0px;
} */

input::-webkit-calendar-picker-indicator {
    display: none;
}

ul.list-upcomingRecordings {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.list-upcomingRecordings li {
    margin-bottom: 15px;
    padding-bottom: 15px;
}

ul.list-upcomingRecordings li:not(:last-child) {
    border-bottom: 1px solid #EDEDED;
}

ul.list-upcomingRecordings li p {
    margin-bottom: 0px;
}

ul.list-pastRecordings {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.list-pastRecordings>li {
    /* margin-bottom: 10px; */
    padding: 10px 0px;
    min-height: 110px;
    border-bottom: 1px solid #e4e4e4cc;
}

.accordion-body-sm>ul.list-pastRecordings>li:first-child {
    padding: 0 0 15px 0px;
    min-height: 100px;
}

.accordion-body-sm>ul.list-pastRecordings>li {
    padding: 15px 0px;
    min-height: 100px;
}

ul.list-pastRecordings li .thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 80px;
    border-radius: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    margin-top: 4px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
}

ul.list-pastRecordings li .thumb:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

ul.list-pastRecordings li .thumb img {
    position: relative;
}

ul.list-pastRecordings li .thumb .play {
    display: block;
    width: 25px;
    height: 25px;
    border-style: solid;
    position: relative;
    box-sizing: border-box;
    border-width: 15px 0px 15px 25px;
    border-color: transparent transparent transparent #FFF;
}


/* ul.list-pastRecordings li .recording-details {
    float: left;
    min-height: 90px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    padding: 5px;
    border-right: 2px solid #EDEDED;
}

ul.list-pastRecordings li .recording-details h4 {
    font-weight: bold;
    margin-bottom: 5px;
}

ul.list-pastRecordings li .recording-details p {
    margin-bottom: 0px;
} */

ul.list-pastRecordings li .recording-duration {
    display: grid;
    grid-template-columns: 90% 10%;
    /* width: 65%; */
    line-height: 70px;
    padding: 10px;
}

ul.list-pastRecordings li .recording-duration>span:last-of-type {
    justify-self: end;
}

.link-wrapper {
    padding: 15px;
}

.btn.btn-info {
    background-color: #7bb0f1;
    font-size: 18px;
    border: 0px;
    border-radius: 30px;
    padding: 10px 50px;
    text-align: center;
    color: #FFF !important;
    font-weight: bold;
    z-index: 9;
}

.btn-transparent {
    width: 100%;
    background-color: transparent;
    font-size: 18px;
    border: 4px solid #d7d7d7;
    border-radius: 30px;
    padding: 10px 50px;
    text-align: center;
    color: #d7d7d7 !important;
    font-weight: bold;
    z-index: 9;
}

.btn.btn-info:active {
    background-color: #417FEC !important;
    border-color: #417FEC !important;
}

.offlineLink.btn.btn-info:active,
.offlineLink.btn.btn-info:focus {
    border-color: #e8e8e8 !important;
    background-color: #E8E8E8 !important;
    color: #999 !important;
    outline: none !important;
    box-shadow: none !important;
    border: none;
}

.btn:focus {
    /* box-shadow: 0 0 0 0.2rem transparent; */
    /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
}

.device-input-wrapper a,
.link-wrapper a {
    color: #7bb0f1 !important;
    font-weight: bold;
    font-size: 16px;
}

.sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 12px;
}

.sidebar a {
    color: #7BB0F1;
    font-weight: bold;
}

.left-open {
    left: 0px!important;
    z-index: 501;
}

.rite-open {
    right: 0px!important;
    z-index: 501;
}

a.btn-sidebar-sm {
    display: block;
    font-weight: normal;
    padding: 25px 15px 15px 50px;
    color: #999 !important;
    text-align: left;
    background-color: #FFF;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 1px 10px #d3d3d3;
    position: relative;
}

a.btn-sidebar-sm .icon {
    position: absolute;
    top: 28px;
    left: 17px;
    width: 30px;
}

a.btn-sidebar-sm .icon img {
    max-width: 24px;
    filter: grayscale(1);
}

a.btn-sidebar-sm .toggle {
    width: 32px;
    height: 16px;
    border: 1px solid #CCC;
    background-color: #FFF;
    border-radius: 10px;
    margin-left: 8px;
    margin-top: 8px;
    position: relative;
}

a.btn-sidebar-sm .toggle:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 16px;
    background-color: #CCC;
    z-index: 2;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

a.btn-sidebar {
    display: block;
    font-weight: normal;
    padding: 16px 15px 16px 50px;
    color: #999 !important;
    text-align: left;
    background-color: #FFF;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 1px 5px #D3D3D3;
    position: relative;
}

a.btn-sidebar .icon {
    position: absolute;
    top: 15px;
    left: 17px;
    width: 30px;
}

a.btn-sidebar .icon img {
    max-width: 24px;
    filter: grayscale(1);
}

a.btn-sidebar .toggle {
    width: 32px;
    height: 16px;
    border: 1px solid #CCC;
    background-color: #FFF;
    border-radius: 10px;
    margin-left: 8px;
    margin-top: 8px;
    position: relative;
}

a.btn-sidebar .toggle:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 16px;
    background-color: #CCC;
    z-index: 2;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.playback-filter-btn {
    width: 100%;
    justify-self: flex-end;
    align-self: center;
}

.playback-filter-btn>div {
    width: 48%;
    display: inline-block;
}

.device-page-container .playback-filter-btn>div {
    width: 50% !important;
}

.playback-filter-btn>div>input {
    display: inline-block;
    margin-left: 30px;
}

.playback-filter-btn>div>span {
    display: inline-block;
    margin-left: 20px;
    position: relative;
    bottom: 10px;
    font-weight: 600;
}

.small-info {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    color: #bababa;
}

.toggler .toggle {
    display: inline-block;
    cursor: pointer;
    width: 46px;
    height: 26px;
    /* border: 0px solid #CCC; */
    background-color: #FFF;
    border-radius: 30px;
    margin-left: 10px;
    margin-top: 4px;
    position: relative;
}

.toggler-sm .toggle {
    display: inline-block;
    cursor: pointer;
    width: 37px;
    height: 19px;
    /* border: 0px solid #CCC; */
    background-color: #FFF;
    border-radius: 30px;
    margin-left: 10px;
    margin-top: 4px;
    position: relative;
}

.toggle-container>.on {
    background-color: #d7e7f7 !important;
}

.toggler .off {
    border: 1px solid #ccc !important;
}

.toggler-sm .off {
    border: 1px solid #ccc !important;
}

.toggler .toggle:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 2px;
    width: 20px;
    height: 19px;
    border-radius: 16px;
    background-color: #CCC;
    z-index: 2;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.toggler-sm .toggle:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 3px;
    width: 12px;
    height: 13px;
    border-radius: 16px;
    background-color: #CCC;
    z-index: 2;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.sleep-mode-toggler {
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 8% 84% 8%;
    font-size: 18px;
}

.sleep-mode-toggler .icon {
    width: 42px;
    height: 42px;
    border-radius: 42px;
    background-color: #D7E7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.sleep-mode-toggler .icon>img {
    width: 42px;
    height: 42px;
}

.sleep-mode-toggler p {
    margin-bottom: 0px;
    line-height: 1;
    align-self: center;
}

.device-input-wrapper {
    margin-bottom: 34px;
    text-align: center;
    clear: both;
}

.device-input-wrapper.noMargin {
    margin: 0px!important;
    padding: 15px 0px;
}

.my-device-box {
    width: 100%;
    text-align: center;
    padding: 25px;
    position: relative;
    margin-bottom: 40px;
    min-height: 380px;
    border-radius: 18px;
    box-shadow: 0 0 6px 0 rgba(91, 91, 91, 0.29);
    background-color: #FFF;
}

.my-device-box img {
    margin-bottom: 20px;
    max-height: 250px;
}

.my-device-box h3 {
    margin-top: 0px;
    margin-bottom: 10px;
}

.my-device-box h3.grayed {
    font-size: 22px;
}

.grayed {
    color: #999!important;
}

.my-device-box p {
    color: #999;
    text-align: center;
}

.my-device-box p span {
    color: #000;
    display: block;
}

.form-group .select-dropdown {
    position: absolute;
    right: 15px;
    top: 13px;
    pointer-events: none;
}

.form-group {
    margin-bottom: 30px;
}

.form-group.noMargin {
    margin-bottom: 0px;
}

.playback-filter-col {
    display: grid;
    grid-template-columns: 40% 50% 10%;
}

.device-page-container>.playback-filter-col {
    grid-template-columns: 30% 55% 15% !important;
}

.playback-filter-text {
    font-size: 13px !important;
    color: #000;
    line-height: 1.3;
}

.noGrayFilter {
    filter: none!important;
}

.fixedScrubber {
    position: fixed;
    z-index: 999;
    bottom: 60px;
    left: 0;
    width: 100%;
    overflow-x: auto;
}

.scrubber-btn-col {
    text-align: right;
}

.scrubber-btn-col>.scrubber-btns {
    display: grid;
    width: 90px;
    grid-template-columns: 50% 50%;
    float: right;
    margin-top: 5px;
    padding: 8px 10px;
    justify-items: center;
    background-color: #fff;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

.scrubber-btn-col>p {
    margin-bottom: 0px;
    margin-right: 5px;
    font-size: 12px;
    color: #999;
}

.scrubber-btn-col>.scrubber-btns>a>img {
    width: 18px;
    margin: 0 7px;
    cursor: pointer;
    align-self: center;
}

.scrubber-btn-col>.scrubber-btns>a:active>img {
    width: 16px;
    margin: 0 7px;
    cursor: pointer;
    align-self: center;
    /* filter: grayscale(1); */
}

.scrubber-btn-col>.scrubber-btns>a {
    filter: grayscale(1);
}

.scrubber-btn-col>.scrubber-btns>a:active {
    filter: none;
}

.playback-container {
    position: relative;
}

.playback-container .close-playback {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 9;
    color: #FFF;
    font-weight: bold;
    font-size: 13px;
}

input::-webkit-calendar-picker-indicator {
    display: none;
}

.grey-title {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    color: #999999;
}


/* 
ul.list-upcomingRecordings {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.list-upcomingRecordings li {
    margin-bottom: 15px;
    padding-bottom: 15px;
}

ul.list-upcomingRecordings li:not(:last-child) {
    border-bottom: 1px solid #EDEDED;
}

ul.list-upcomingRecordings li p {
    margin-bottom: 0px;
} */

ul.motion-options {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.motion-options:not(:last-child) {
    border-bottom: 3px solid #f5f5f5;
}

ul.motion-options>li {
    margin-top: 30px;
    margin-bottom: 30px;
}

ul.list-pastRecordings {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.list-pastRecordings li {
    padding: 10px 0px;
    min-height: 110px;
}

.rw-widget-container {
    border: 1px solid #999;
}

ul.list-pastRecordings li .recording-details {
    /* float: left; */
    min-height: 90px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    padding: 5px;
    /* border-right: 2px solid #EDEDED; */
}

ul.list-pastRecordings li .recording-details h4 {
    font-weight: bold;
    margin-bottom: 5px;
}

ul.list-pastRecordings li .recording-details p {
    margin-bottom: 0px;
}

.link-wrapper {
    padding: 15px;
}

.btn.btn-info {
    background-color: #7BB0F1;
    font-size: 18px;
    border: 0px;
    border-radius: 30px;
    padding: 10px 50px;
    text-align: center;
    color: #FFF;
    font-weight: bold;
}

.toggler .toggle.on:before {
    background-color: #7BB0F1;
    left: 22px;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.toggler-sm .toggle.on:before {
    background-color: #7BB0F1;
    left: 21px;
    top: 3px;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.checkmarker .checkmark {
    display: inline-block;
    height: 32px;
    padding-left: 40px;
    font-size: 16px;
    color: #999;
    position: relative;
    line-height: 32px;
}

.checkmarker .checkmark:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    border: 2px solid #CCC;
}

.checkmarker .checkmark.on {
    color: #000
}

.checkmarker .checkmark.on:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    border: 0px;
    background-image: url(../images/checkmark.svg);
}

.checkmarker.boldFont .checkmark {
    font-weight: bold;
    color: #333;
}

.device-error-note {
    max-width: 180px;
    font-size: 11px;
    border: 1px solid #bd8787;
    padding: 5px;
    background-color: #fff0f3;
}

.thick {
    border-top: 2px solid #D8D8D8;
}

.right-action {
    margin-top: 15px;
    float: right;
}

.device-selection {
    clear: both;
    border-bottom: 1px solid #D8D8D8;
    padding-top: 15px;
    padding-bottom: 15px;
}

.device-selection:last-child {
    border-bottom: 0px;
}

.device-selection .toggler {
    float: right
}

.device-selection h4 {
    color: #272727;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 0px;
}

a.btn-sidebar.active {
    background-color: #D7E7F7;
    color: #7BB0F1 !important;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
}

a.btn-sidebar.active .icon img {
    filter: none;
}

a.btn-sidebar.active .toggle {
    width: 32px;
    height: 16px;
    border: 1px solid #FFF;
    background-color: #FFF;
    border-radius: 10px;
    margin-left: 8px;
    margin-top: 8px;
    position: relative;
}

a.btn-sidebar.active .toggle:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 17px;
    width: 12px;
    height: 12px;
    border-radius: 16px;
    background-color: #7BB0F1;
    z-index: 2;
}

ul.nav.nav-pills {
    margin-bottom: 15px;
}

ul.nav.nav-pills a {
    font-size: 16px;
    color: #999;
}

ul.nav.nav-pills a:hover,
ul.nav.nav-pills a:focus,
ul.nav.nav-pills a:active {
    color: #7BB0F1;
    background-color: transparent;
}

.my-shares {
    padding: 20px 10px;
}

.my-shares .listShare {
    position: relative;
    border-bottom: 1px solid #D8D8D8;
    padding: 20px 0px;
    clear: both
}

.my-shares .listShare.last {
    border-bottom: 0px;
}

.my-shares .listShare:last-of-type {
    border-bottom: 0px;
}

.listShare.expired ul.members {
    filter: grayscale(1);
}

ul.nav.nav-pills li {
    margin-right: 25px;
    float: left;
    border-bottom: 0px!important;
    height: auto!important;
    padding: 0px!important;
}

.main>ul.nav.nav-pills li {
    margin-right: 35px;
    float: left;
    border-bottom: 0px!important;
    height: auto!important;
    padding: 0px!important;
}

.group-recording>ul.nav.nav-pills li {
    margin-right: 60px;
    float: left;
    border-bottom: 0px!important;
    height: auto!important;
    padding: 0px!important;
}

.group-recording>ul.nav.nav-pills li:last-of-type {
    margin-right: 0px;
}

ul.nav.nav-pills li.active a {
    color: #000;
    font-weight: 600;
    background-color: #FFF;
    padding-left: 0px;
    position: relative;
}

ul.nav.nav-pills li.active a:before {
    content: "";
    position: absolute;
    bottom: 5px;
    height: 4px;
    width: 30px;
    border-radius: 10px;
    background-color: #7BB0F1;
}

.grid-view-icon {
    margin: 30px 0 0 30px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.grid-fullscreen-icon {
    margin: 0px 0 0 30px;
    display: inline-block;
    position: absolute;
    top: 8%;
    left: 0;
    cursor: pointer;
}

.recording-status-snip {
    width: 31%;
    display: inline-block;
    position: absolute;
    top: 6%;
    left: 36%;
    padding: 5px 0 5px 45px;
    background-image: url('../images/snip.png');
    background-size: 90% 100%;
    background-repeat: no-repeat;
    font-size: 15px;
    letter-spacing: 1.1px;
    cursor: pointer;
}

.recording-status-snip>p {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    background-color: #fc7866;
    border-radius: 50%;
    color: #fc7866;
    position: relative;
    top: 3px;
    margin-right: 10px;
    /* right: 10px; */
}

.recording-snip-full {
    width: 20%;
    display: inline-block;
    position: absolute;
    top: 4%;
    left: 40%;
    padding: 5px 0 5px 45px;
    background-image: url('../images/snip.png');
    background-size: 95% 100%;
    background-repeat: no-repeat;
    font-size: 16px;
    letter-spacing: 1.1px;
    cursor: pointer;
}

.recording-snip-full>p {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
    background-color: #fc7866;
    border-radius: 50%;
    color: #fc7866;
    position: relative;
    top: 3px;
    margin-right: 10px;
    /* right: 10px; */
}

.sharelisting-grid {
    display: grid;
    grid-template-columns: 70% 30%;
}

.sharelisting-grid>.rightActions {
    display: grid;
    grid-template-columns: 50% 50%;
}

.sharelisting-grid>div {
    align-self: center;
}

.sharelisting-grid>div>p {
    margin-bottom: 0px;
    letter-spacing: 0.34px;
}

.sharelisting-grid>div>h4 {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 550;
    letter-spacing: 0.02px;
}

.force-centered {
    text-align: center;
    color: #040404;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px !important;
}

.blue-text {
    color: #7BB0F1;
    font-weight: bold;
    font-size: 16px;
}

.recordCapTimer {
    width: 26px;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    border-radius: 4.6px;
    background-color: #7bb0f1;
    text-align: center;
    padding: 3px;
    position: relative;
    top: 3px;
}

.camera-card {
    display: block;
    width: 365px;
    border: 0px;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    min-height: 190px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 1px 5px #D3D3D3;
}

.camera-card:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.511379551820728) 0%, rgba(244, 244, 244, 0) 60%, rgba(255, 255, 255, 0) 100%)
    /* background-image: linear-gradient(0deg, rgba(100, 100, 100, 1) 0%, rgba(244, 244, 244, 0) 60%, rgba(255, 255, 255, 0) 100%); */
}

.camera-card .offline-info {
    position: absolute;
    font-size: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 0.70);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFF;
    z-index: 8
}

.camera-card .offline-info div {
    width: 90%;
    position: relative;
    z-index: 999 !important;
}

.camera-card .offline-info div a {
    color: #7BB0F1;
    font-weight: bold;
}

.camera-card .status-label {
    width: 50px;
    height: 18px;
    padding: 3px;
    position: absolute;
    top: 18px;
    left: 18px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
    z-index: 6
}

.camera-card .shared-label {
    width: 60px;
    height: 18px;
    /* float: right; */
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 3px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    background-color: #cee4fd;
    color: #2196f3;
    border-radius: 10px;
    z-index: 9;
}

.camera-card .update-label {
    width: 100px;
    height: 18px;
    padding: 3px;
    position: absolute;
    top: 18px;
    left: 18px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    background-color: #cee4fd;
    color: #2196f3;
    border-radius: 10px;
    z-index: 6;
}

.camera-card .status-label.online {
    background-color: #E5F4EA;
    color: #9FC576;
}

.camera-card .status-label.offline {
    background-color: #ffc8d0;
    color: #ef0f0f;
}

.camera-card.expired .camera-body {
    display: none;
}

.camera-card .camera-body {
    width: 100%;
    padding: 15px 25px;
    font-size: 14px;
    color: #FFF;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
}

.camera-card .camera-body ul.members {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 20px;
    right: 40px;
}

.camera-card .camera-body ul.members li {
    float: right;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* z-index: 7; */
}

.camera-card .camera-body ul.members li:not(:last-child) {
    margin-left: -10px;
}

.camera-card .camera-body ul.members li:first-of-type {
    z-index: 5;
}

.camera-card .camera-body ul.members li.more-members {
    float: right;
    width: 30px;
    height: 30px;
    right: -62px;
    border-radius: 30px;
    background-color: #c4ddfd;
    color: #333;
    line-height: 30px;
    text-align: center;
    z-index: 5;
}

.camera-card .camera-body h4 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.camera-body>.light-text {
    font-size: 12px;
    margin-bottom: 5px;
    color: #d7d7d7;
}

.camera-single {
    margin-bottom: 8px;
    border-radius: 25px;
    background-color: #343434;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* min-height: 578px;
    max-height: 578px; */
    position: relative;
    overflow: hidden;
    height: 575px;
}

.camera-single.playback-mode {
    background-color: #fff;
    border-radius: 24px;
    border: solid 1px #9b9b9b;
}

.camera-single.playback-mode:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: none;
}

.camera-single:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    z-index: 9;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.fullscreen-mode:before {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.camera-single .status-label {
    position: absolute;
    top: 40px;
    right: 45px;
    width: 65px;
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    height: 20px;
}

.camera-single .status-label.online {
    background-color: #E5F4EA;
    color: #9FC576;
}

.camera-single .status-label.offline {
    background-color: #ffc8d0;
    color: #ac3e3e;
}

.camera-single .status-label.playback {
    width: 80px;
    background-color: #FCDEDA;
    color: #FC7866;
}

.camera-bottom-controls {
    position: absolute;
    bottom: 0;
    height: 100px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
}

.past-recording>.camera-single>.camera-bottom-controls {
    position: absolute;
    bottom: 0;
    height: 100px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.camera-bottom-controls .left-side,
.camera-bottom-controls .right-side {
    padding: 30px 40px;
}

.camera-bottom-controls ul.controls {
    list-style: none;
    margin: 0;
    padding: 0;
}

.camera-bottom-controls ul.controls li {
    display: inline-block;
    width: 75px;
    margin-right: 10px;
}

.camera-bottom-controls ul.controls li:first-of-type {
    margin-right: 18px;
}

.camera-bottom-controls ul.controls li a {
    opacity: 1;
}

.camera-bottom-controls ul.controls li a:hover {
    opacity: 1;
}

.profile-header .profile-pic {
    width: 110px;
    height: 110px;
    border-radius: 110px;
    background-color: #D7E7F7;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin-right: 40px;
    position: relative;
}

.profile-header .profile-pic .edit-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    position: absolute;
    bottom: 0px;
    right: -10px;
    z-index: 1;
    background-color: #FFF;
    border: 1px solid #7bb0f1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-header .profile-pic span {
    font-size: 40px;
    font-weight: bold;
    color: #7BB0F1;
}

.profile-header.sm {
    min-height: 70px;
}

.profile-header.sm .profile-pic {
    width: 70px;
    height: 70px;
}

.profile-header.sm .profile-pic .edit-profile-pic {
    width: 25px;
    height: 25px;
}

.profile-header.sm .profile-pic .edit-profile-pic img {
    max-height: 16px;
}

.profile-header.sm .profile-pic span {
    font-size: 30px;
}

.verticalBuffer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.profile-header.lg {
    min-height: 130px;
    position: relative;
}

.profile-header.lg .profile-pic {
    width: 130px;
    height: 130px;
    margin: 50px auto 30px auto;
    float: none;
}

.profile-header.lg .profile-pic .edit-profile-pic {
    width: 45px;
    height: 45px;
}

.profile-header.lg .profile-pic span {
    font-size: 43px;
}

.profile-header.sm h3 {
    padding-top: 15px;
    font-size: 20px;
    margin-bottom: 0px;
}

.profile-header.sm p {
    font-size: 13px;
    color: #999;
}

.profile-header .dropdown {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 15px;
}

.profile-header {
    margin-bottom: 30px;
    min-height: 110px;
    position: relative;
}

.profile-header .dropdown-item {
    font-size: 15px;
    padding: 0.5rem 1.5rem;
}

.profile-header h3 {
    padding-top: 40px;
    font-size: 24px;
}

.profile-header .profile-menu {
    position: absolute;
    top: 35px;
    right: 0px;
}

.profile-menu .dropdown-menu {
    top: -28px !important;
    /* right: -55px; */
    right: 0px;
    left: auto!important;
    border: none;
    box-shadow: 0 2px 9px -2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 15px;
}

.profile-menu .dropdown-menu>button:hover {
    background-color: transparent;
}

.format-menu .dropdown-menu {
    border: none;
    box-shadow: 0 2px 9px -2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 15px;
    transform: translate3d(-130px, 0px, 0px) !important;
}

.notification-menu .dropdown-menu {
    border: none;
    box-shadow: 0 2px 9px -2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 15px;
    transform: translate3d(-70px, 0px, 0px) !important;
}

.notification-menu.single .dropdown-menu {
    border: none;
    box-shadow: 0 2px 9px -2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 15px;
    transform: translate3d(-25px, 0px, 0px) !important;
}

.notification-menu .btn-secondary {
    border: none;
}

.notification-menu .dropdown-menu>button {
    margin: 10px 0;
    font-size: 16px;
    color: #000000;
}

.notification-menu .dropdown-menu>button:hover {
    background-color: transparent;
}

.notification-menu .dropdown-menu>button:last-of-type {
    margin: 5px 0;
    padding-top: 10px;
    font-size: 16px;
    border-top: 1px solid #f5f5f5;
}

.notification-menu.single .dropdown-menu>button:last-of-type {
    margin: 5px 0;
    padding-top: 0px;
    font-size: 16px;
    border-top: 0px solid #f5f5f5;
}

.btn-secondary:not(:disabled):not(.disabled):active {
    border: none;
    background-color: #fff;
}

.profile-menu .dropdown-menu>.dropdown-item {
    color: #000;
}

.profile-header .profile-menu .btn {
    border: 0px;
}

.profile-menu.dropdown .btn-lg {
    background-color: transparent!important;
    border: 0px;
    color: #000!important;
}

.dropdown-menu .dropdown-item {
    font-size: 15px;
}

.profile-header .btn.btn-secondary,
.format-menu .btn.btn-secondary,
.notification-menu .btn.btn-secondary {
    background-color: #FFF;
    color: #000;
    border: none;
}

.format-menu .dropdown-menu>button:active,
.notification-menu .dropdown-menu>button:active {
    color: #212529;
}

.notification-date-filters {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 5%;
}

.format-menu .btn.btn-secondary:active {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.btn.btn-secondary:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.profile-page-container {
    padding-left: 2%;
}

.profile-page-container hr {
    clear: both;
    border-top: 2px solid #F5F5F5;
    margin: 25px auto;
}

.zone-mismatched {
    clear: both;
    background-color: #fef4d9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

ul.motion-detections,
ul.list-devices,
.groups-notification {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.motion-detections li {
    padding-bottom: 20px;
    border-bottom: 1px solid #CCC;
    margin-bottom: 15px;
}

ul.list-devices li {
    padding-bottom: 36px;
    border-bottom: 1px solid #CCC;
    margin-bottom: 15px;
}

.motion-detections {
    /* margin-bottom: 20px !important; */
    /* border-bottom: 1px solid #ccc; */
}

ul.motion-detections li:last-of-type {
    margin-bottom: 20px;
}

.motion-detections>li {
    min-height: 75px;
}

ul.motion-detections li .thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 55px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
}

ul.motion-detections li .thumb .play {
    display: block;
    width: 12px;
    height: 17px;
    border-style: solid;
    position: relative;
    box-sizing: border-box;
    border-width: 8px 0px 8px 15px;
    border-color: transparent transparent transparent #FFF;
}

ul.motion-detections li .thumb:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

ul.motion-detections li .thumb img {
    position: relative;
}

.groups-notification>li {
    min-height: 114px;
}

.groups-notification>li>h4 {
    font-size: 14px;
}

.groups-notification {
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
}

.groups-notification:last-of-type {
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 0px solid #ccc;
}

ul.groups-notification li .thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 102px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 1px 5px #D3D3D3;
    float: left;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
}

ul.groups-notification li .thumb:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

ul.groups-notification li .thumb img {
    position: relative;
}

.groups-notification>li>.notes {
    font-size: 14px;
    color: #999;
}

ul.motion-detections li h4,
ul.list-devices li h4 {
    font-size: 15px;
    color: #000;
}

ul.motion-detections li p,
ul.list-devices li p {
    color: #999;
}

ul.list-devices li .thumb {
    width: 180px;
    height: 100px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
}

ul.list-devices li .thumb:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

ul.list-devices li .thumb img {
    position: relative;
}

ul.list-devices li h4 {
    font-size: 15px;
    color: #000;
}

ul.list-devices li p {
    color: #999;
}

ul.list-devices li .status-label {
    position: relative;
    width: 65px;
    text-align: center;
    font-weight: 600;
    border-radius: 15px;
    height: 19px;
    font-size: 12px;
    padding: 3px 8px;
}

ul.list-devices li .status-label.online {
    background-color: #E5F4EA;
    color: #9FC576;
}

ul.list-devices li ul.members {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
}

ul.list-devices li ul.members li {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

ul.list-devices li ul.members li:not(:last-child) {
    margin-left: -10px;
}

ul.list-devices li ul.members li.more-members {
    float: right;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #efefef;
    color: #333;
    line-height: 30px;
    text-align: center;
}

.rightActions ul.members {
    float: left;
}

ul.members {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    /* width: 90px; */
}

ul.members li {
    position: relative;
    float: right;
    width: 30px!important;
    height: 30px!important;
    border-radius: 30px!important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

ul.members li:not(:last-child) {
    margin-left: -5px;
}

ul.members li:last-child {
    margin-left: -5px;
}

ul.members li.more-members {
    float: right;
    width: 30px!important;
    height: 30px!important;
    border-radius: 30px!important;
    background-color: #FFF;
    color: #333;
    line-height: 30px;
    text-align: center;
}

.storage-type-list {
    width: 100%;
}

.storage-type-list>p {
    width: 100%;
    margin-bottom: 0;
    padding: 2%;
    font-size: 16px;
    font-weight: 800;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.storage-type-list>p>img {
    float: right;
}

.addition-storage-cta {
    display: grid;
    width: 95%;
    height: 72px;
    grid-template-columns: 12% 78% 10%;
    justify-content: center;
    position: relative;
    bottom: 200px;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.addition-storage-cta>div {
    justify-self: center;
    align-self: center;
}

.addition-storage-cta>div>img {
    width: 32px;
}

.addition-storage-cta>p {
    margin-bottom: 0;
    font-size: 18px;
    color: #272727;
    align-self: center;
}

.addition-storage-cta>img {
    width: 29px;
    height: 29px;
    margin-right: 20px;
    align-self: center;
    justify-self: end;
    cursor: pointer;
}

.camera-actions,
.camera-actions-sm {
    display: flex;
    flex-wrap: wrap;
}

.overhead-container {
    width: 230px;
    background-color: white;
    border-radius: 26px;
    border-top-right-radius: 0px;
    position: absolute;
    right: 40px;
    top: 40px;
    z-index: 99;
}

.overhead-container>p {
    width: 85%;
    margin: 0 auto;
    padding-top: 18px;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.26px;
    color: #999999;
}

.terms-text {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    color: #9b9b9b;
    line-height: 1.8;
}

.terms-text a {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: justify;
    color: #7bb0f1;
}

.filter-sizing {
    width: 125px !important;
}

.camera-actions.fullscreen-features>a.action-btn>span {
    font-size: 12px;
}

.overhead-container>p>img {
    float: right;
    width: 14px;
    cursor: pointer;
}

.camera-actions.fullscreen-features {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.camera-actions.fullscreen-features>.action-btn {
    min-height: 60px;
    margin: 10px 5px;
    padding: 5px 0;
    display: grid;
    grid-template-columns: 90%;
    grid-row-gap: 10px;
    justify-content: center;
    align-content: center;
    font-weight: normal;
    color: #999;
    text-align: left;
    background-color: #FFF;
    border-radius: 10px;
    width: 90%;
    box-shadow: none;
    position: relative;
    text-align: center;
    cursor: pointer;
}

.camera-actions.fullscreen-features>.action-btn.active {
    background-color: #D7E7F7;
    color: #7BB0F1;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

.custom-sizin {
    height: 26px;
    object-fit: contain;
}

.record-sizin {
    height: 40px;
    object-fit: none;
}

.shareIcon {
    width: 32px !important;
}

.camera-actions.fullscreen-features>a.action-btn .ico img {
    max-width: 40px;
    max-height: 40px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.camera-actions.fullscreen-features>a.action-btn .ico {
    width: auto;
}

.camera-actions.fullscreen-features>.action-btn:hover {
    box-shadow: none;
}

.camera-actions.fullscreen-features>.action-btn:hover.active {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

.camera-actions>a:active,
.camera-actions-sm>a:active {
    background-color: #D7E7F7;
    color: #7BB0F1;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

.camera-actions>.action-btn {
    min-height: 60px;
    margin: 5px;
    padding: 5px;
    display: grid;
    grid-template-columns: 30% 60%;
    justify-content: space-evenly;
    align-content: center;
    font-weight: normal;
    color: #999;
    text-align: left;
    background-color: #FFF;
    border-radius: 10px;
    width: 135px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    position: relative;
}


/* .alert-collapse-cta-wrapper>.action-btn {
        width: 200px !important;
    } */

.camera-actions-sm>.action-btn {
    min-height: 60px;
    margin: 5px;
    display: grid;
    grid-template-columns: 30% 60%;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: normal;
    color: #999;
    text-align: left;
    background-color: #FFF;
    border-radius: 10px;
    width: 135px;
    box-shadow: 0px 1px 10px #D3D3D3, inset 0px 0px 10px rgba(0, 0, 0, 0);
    position: relative;
}

.camera-actions-sm.extra>.action-btn {
    width: 136px !important;
}

a.action-btn .ico {
    width: 30px;
    align-self: center;
    justify-self: center;
}

a.action-btn>span {
    font-size: 14px;
    align-self: center;
}

a.action-btn .ico img {
    width: 26px;
    /* max-height: 26px; */
    filter: grayscale(1);
}

a.action-btn:hover {
    /* color: #7BB0F1; */
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

a.action-btn.active {
    background-color: #D7E7F7;
    color: #7BB0F1;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    font-weight: 550;
}

a.action-btn.active .icon img {
    filter: none;
}

a.bigLink {
    font-size: 18px;
    font-weight: bold;
    color: #7bb0f1;
}

.comment-text {
    color: #999;
}

.infoBox {
    border: 0.5px solid #CCC;
    padding: 35px;
    color: #333;
    text-align: center;
    min-height: 470px;
    border-radius: 10.7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.infoBox img {
    max-height: 230px;
    width: auto;
    margin-bottom: 20px;
}

.info-note {
    margin-bottom: 18px;
    /* padding: 10px 45px; */
    padding: 2% 10% 2% 4%;
    font-size: 16px;
    border-radius: 15px;
    background-color: #FFF4DA;
}

.recordingActiveTag {
    /* padding: 1px 8px;
        font-size: 10px;
        color: #fff;
        background-color: #7bb0f1;
        border-radius: 25px; */
    padding: 2px 8px 0;
    font-size: 10px;
    color: #fff;
    background-color: #7bb0f1;
    border-radius: 25px;
    float: right;
    line-height: 1.1;
    text-transform: capitalize;
}

.recordingScheduledTag {
    padding: 2px 8px 0;
    font-size: 10px;
    color: #fff;
    background-color: #7bb0f1;
    border-radius: 25px;
    float: right;
    line-height: 1.1;
    border: 1px solid #d2e6ff;
    text-transform: capitalize;
}

.recordingScheduleExpiredTag {
    padding: 2px 8px 0;
    font-size: 10px;
    color: #7bb0f1;
    background-color: #ffffff;
    border-radius: 25px;
    float: right;
    line-height: 1.1;
    border: 1px solid #d2e6ff;
    text-transform: capitalize;
}

.info-note-red {
    margin-bottom: 18px;
    padding: 2% 10% 2% 4%;
    font-size: 18px;
    border-radius: 15px;
    font-weight: bold !important;
    color: #fc7866 !important;
    background-color: #fcdeda;
}

.beta-update>.info-note-red {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

.device-page-container>.info-note {
    padding: 2.5% 9% 2.5% 3%;
    font-size: 18px;
    line-height: 1.39;
    letter-spacing: 0.39px;
    color: #272727;
}

.notification-wrapper {
    width: 100%;
}

.notification-details {
    padding: 8px 0;
    /* position: relative;
    top: 10px; */
}

ul.list-pastRecordings li .thumb-sm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 75px;
    border-radius: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
}

ul.list-pastRecordings li .thumb-sm:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

ul.list-pastRecordings li .thumb-sm img {
    position: relative;
}

ul.list-pastRecordings li .thumb-sm .play {
    display: block;
    width: 2px;
    height: 20px;
    border-style: solid;
    position: relative;
    box-sizing: border-box;
    border-width: 10px 0px 10px 20px;
    border-color: transparent transparent transparent #FFF;
}

.alert-li {
    display: grid;
    grid-template-columns: 80% 10% 10%;
    border-bottom: 1px solid #eee;
}

.alert-li>span {
    width: 15px;
    height: 15px;
    background-color: #fc7866;
    border-radius: 50%;
    justify-self: right;
    align-self: center;
}

.alert-collapse {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.alert-collapse>img {
    margin-bottom: 10px;
    border-radius: 15px;
}

.alert-collapse-cta-wrapper {
    padding: 5px;
}

.icon-box {
    padding: 5px 10px;
    justify-self: end;
}

.icon-box>span>a>img {
    width: 24px;
    height: 24px;
}

.cta-span {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #7bb0f1;
    cursor: pointer;
}

.read {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: justify;
    color: #7bb0f1 !important;
    cursor: pointer;
}

.unreadDot {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20%;
    background-color: #fc7866;
    border-radius: 50%;
    justify-self: right;
    align-self: center;
}

.radio-box {
    margin: 0 auto;
    width: 40%;
    display: grid;
    grid-template-columns: 10% 80%;
    column-gap: 15px;
    justify-items: left;
}

.radio-box>label {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.39px;
    color: #272727;
}

.radio-box-lg {
    margin: 0 auto;
    width: 55%;
    display: grid;
    grid-template-columns: 5% 80%;
    column-gap: 15px;
    justify-items: left;
}

.radio-box-lg>label {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.39px;
    color: #272727;
}

.other-alert-msg {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.02px;
    color: #272727;
}

.no-group-wrapper {
    margin: 0 auto;
    width: 80%;
    display: grid;
    grid-template-columns: 10% 85%;
    row-gap: 10px;
    text-align: left;
}

.no-group-wrapper>label {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #999999;
}

.no-group-wrapper>label.active {
    color: #000 !important;
}

.storage-recordings {
    position: relative;
    bottom: 150px;
}

.storage-recordings>h5 {
    font-weight: 900;
    margin-bottom: 2%;
}

.storage-recordings>h5>.profile-menu.dropdown {
    float: right;
    font-weight: 500;
    bottom: 8px;
    left: 10px;
}

.storage-recordings>h5>.profile-menu.dropdown>button:focus,
.storage-recordings>h5>.profile-menu.dropdown>button:active {
    box-shadow: none;
    outline: none;
    border: none;
}

.storage-recordings>h5>.dropdown>.dropdown-menu .dropdown-item {
    padding: 1rem 2rem;
}

.storage-recordings>h5>.dropdown>.dropdown-menu .dropdown-item:last-of-type {
    border-top: 1px solid #eee;
}

.storage-recordings>.filter-wrapper>.date-filters {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    margin: 5% 0 2% 0;
}

.storage-recordings>.filter-wrapper>.type-filters {
    margin: 5% 0;
}

.storage-recordings>.filter-wrapper>.type-filters>p {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 20px;
    border-radius: 30.8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.storage-recordings>.filter-wrapper>.type-filters>.active {
    color: #7bb0f1;
    font-weight: 800;
    background-color: #d6e9ff;
}

.storage-recordings>.filter-wrapper {
    margin-bottom: 2%;
    border-bottom: 5px solid #eee;
}

.storage-recordings>.recording-li {
    width: 100%;
    display: grid;
    grid-template-columns: 15% 28% 20% 25%;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 3px solid #d8d8d8;
}

.storage-recordings>.recording-li>img {
    width: 120px;
    height: 80px;
    border-radius: 15px;
}

.recording-li h4 {
    margin: 4px 0 5px 0;
    font-weight: 800;
}

.recording-li .small-notes {
    border-right: 1px solid #d8d8d8;
}

.recording-li .small-notes p {
    margin-bottom: 2px;
}

.recording-li .small-notes span {
    color: #999;
}

.positioned-text {
    height: fit-content;
    align-self: center;
    padding-top: 35px;
}

.recording-li>.dropdown {
    justify-self: end;
}

.storage-arc {
    width: 40%;
    margin: 0 auto;
    padding-top: 5%;
}

.storage-arc.tab-mode {
    width: 50%;
    margin: 0 auto;
    padding-top: 5%;
}

.storage-arc-text {
    position: relative;
    bottom: 250px;
}

.storage-arc-text>p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #999999;
}

.storage-indicator {
    display: inline-block;
    position: relative;
    bottom: 260px;
    left: 44%;
}

.storage-indicator.tab-mode {
    display: inline-block;
    position: relative;
    bottom: 315px;
    left: 38%;
}

.storage-indicator>p {
    width: 100%;
    margin-bottom: 0;
    font-size: 17px;
    color: #999;
    text-align: center;
}

.storage-indicator>span {
    font-size: 33px;
    font-weight: 900;
}

.storage-indicator>span>sup {
    font-size: 18px;
    font-weight: 900;
    top: -10px;
}

.snapshot {
    width: 655px;
    margin: 0 auto;
    text-align: center;
}

.snapshot>img {
    /* position: relative; */
    /* bottom: 10%;
    left: 5%; */
}

.snapshot>.actionbox {
    position: relative;
    display: grid;
    width: 100%;
    margin-top: 15px;
    padding: 10px;
    grid-template-columns: 9% 86%;
    grid-column-gap: 3%;
    align-items: center;
    justify-items: center;
    color: #fff;
    text-align: left;
    border-radius: 14.2px;
    box-shadow: 0 -12px 16px 0 rgba(71, 71, 71, 0.09);
    border: solid 1.4px #979797;
    background-color: rgba(255, 255, 255, 0.15);
}

.snapshot>.actionbox>div {
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
}

.snapshot>.actionbox>p {
    margin-bottom: 0px;
    font-size: 16px;
}

.snapshot>.actionbox>div:last-of-type>span {
    margin-top: 2px;
}

.snapshot>.closebox {
    position: relative;
    top: 95px;
    display: grid;
    grid-template-columns: 100%;
    font-size: 16px;
    justify-items: center;
    color: #fff;
}

.playback-form {
    width: 40%;
}

.restart-popup,
.security-popup {
    text-align: center;
}

.restart-popup>h4,
.security-popup>h4 {
    margin: 2% 0;
}

.restart-popup>.illustration-holder {
    width: 160px;
    height: 125px;
    margin: 0 auto;
}

.security-popup>.illustration-holder {
    width: 230px;
    height: 255px;
    margin: 0 auto;
}

.oops-popup>.illustration-holder {
    width: 180px;
    height: 200px;
    margin: 0 auto;
}

.success-popup>.illustration-holder {
    width: 132px;
    height: 116px;
    margin: 10% auto;
}

.mute-popup>.illustration-holder {
    width: 235px;
    height: 200px;
    margin: 10% auto;
}

.security-popup>.text-holder,
.oops-popup>.text-holder,
.restart-popup>.text-holder {
    width: 90%;
    margin: 2% auto 0;
    line-height: 1.33;
    letter-spacing: 0.39px;
}

.success-popup>.text-holder {
    width: 84%;
    margin: 2% auto 0;
    line-height: 1.33;
    letter-spacing: 0.39px;
}

.mute-popup>.text-holder {
    width: 84%;
    margin: 12% auto 0;
    line-height: 1.33;
    letter-spacing: 0.39px;
}

.oops-popup>.text-holder>h1,
.success-popup>.text-holder>h1 {
    margin-bottom: 3%;
    font-weight: 900;
}

.mute-popup>.text-holder>h4,
.success-popup>.text-holder>h4,
.security-popup>.text-holder>h4,
.oops-popup>.text-holder>h4 {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: normal;
}

.grid-two-col {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 50%;
    margin: 0 auto;
}

.other-noti-li>h4 {
    font-size: 16px;
    margin-bottom: 15px;
}

.other-noti-li>p {
    color: #272727;
    margin-bottom: 5px;
    line-height: normal;
}

.other-noti-li>span {
    color: #999;
    font-size: 14px;
}

.other-noti-li>.other-noti-btns {
    color: #7bb0f1;
}

.error-player {
    /* width: 100%;
        padding-bottom: 10%; */
    display: flex;
    width: 100%;
    margin-top: 10%;
    padding-bottom: 10%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.error-player-overlay {
    width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0;
    background-color: #343434;
    width: 100%;
    margin-top: 0;
    padding-bottom: 15%;
}

.error-player img {
    width: 15%;
    /* margin: 5% 42.5%; */
    margin-bottom: 15px;
}

.error-player p {
    margin-bottom: 85px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.39px;
    text-align: center;
    color: #ffffff;
}

.error-player button {
    /* margin: 0 37%; */
    position: relative;
}

.storage-main .error-player button {
    /* margin: 0 38%; */
    position: relative;
}

.fullscreen-mode .error-player button {
    /* margin: 0 42%; */
    position: relative;
}

.preset-sidebar p {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.02px;
    color: #272727;
}

.preset-sidebar p>img {
    width: 20px;
    float: right;
    position: relative;
    bottom: 5px;
    filter: grayscale(1);
    cursor: pointer;
}

.preset-btn {
    margin: 15% auto;
    width: 50%;
    object-fit: contain;
    background-image: url("../images/oval.png");
    background-size: 100%;
    height: 165px;
}

.preset-btn-fullscreen {
    margin: 0% auto;
    width: 155px;
    object-fit: contain;
    background-image: url("../images/oval.png");
    background-size: 100%;
    height: 155px;
    position: absolute;
    right: 30px;
    bottom: 10%;
    opacity: 0.9;
    z-index: 99;
}

.preset-btn>img,
.preset-btn-fullscreen>img {
    filter: grayscale(1);
}

.preset-btn>.top:active,
.preset-btn>.right:active,
.preset-btn>.bottom:active,
.preset-btn>.left:active {
    filter: grayscale(0);
}

.preset-btn-fullscreen>.top:active,
.preset-btn-fullscreen>.right:active,
.preset-btn-fullscreen>.bottom:active,
.preset-btn-fullscreen>.left:active {
    filter: grayscale(0);
}

.preset-btn>img {
    width: 42px;
    position: relative;
}

.preset-btn>.top {
    left: 36%;
    top: 11%;
    transform: rotate(-90deg);
}

.preset-btn>.right {
    float: right;
    top: 36%;
    right: 10%;
}

.preset-btn>.bottom {
    top: 64%;
    left: 13%;
    transform: rotate(90deg);
}

.preset-btn>.left {
    float: left;
    top: 12%;
    left: 10%;
    transform: rotate(180deg);
}


/* Fullscreen btn */

.preset-btn-fullscreen>img {
    width: 40px;
    position: relative;
}

.preset-btn-fullscreen>.top {
    left: 36%;
    top: 11%;
    transform: rotate(-90deg);
}

.preset-btn-fullscreen>.right {
    float: right;
    top: 36%;
    right: 10%;
}

.preset-btn-fullscreen>.bottom {
    top: 64%;
    left: 13%;
    transform: rotate(90deg);
}

.preset-btn-fullscreen>.left {
    float: left;
    top: 12%;
    left: 10%;
    transform: rotate(180deg);
}

.offlineDevice {
    cursor: not-allowed !important;
    background-color: #E8E8E8 !important;
    color: #999 !important;
}

.offlineLink {
    cursor: not-allowed !important;
    background-color: #E8E8E8 !important;
    color: #999 !important;
}

.offlineLink:active {
    cursor: not-allowed !important;
    background-color: #E8E8E8 !important;
    color: #999 !important;
}

.offlineDevice>.ico {
    filter: grayscale(1);
}

.element-disabled,
.element-disabled a,
.element-disabled div,
.element-disabled label {
    cursor: not-allowed !important;
}

.error-player.timedout {
    margin-top: 360px;
}

.fullscreen-mode {
    margin-bottom: 0px;
    position: fixed;
    width: 100%;
    height: 100% !important;
    max-height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 0;
}

.fullscreen-preset-btn {
    border-radius: 30.8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    width: 140px !important;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    text-align: center;
    color: #999999;
    cursor: pointer;
}

.fullscreen-header {
    width: 100%;
    padding: 25px 30px;
    position: absolute;
    top: 0;
}

.fullscreen-header>img {
    cursor: pointer;
}

.fullscreen-header>p {
    margin: 0 0 0 25px;
    display: inline-block;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.51px;
    color: #ffffff;
    position: relative;
    top: 4px;
}

.fullscreen-header>img {
    float: right;
    width: 2%;
    position: relative;
    top: 10px;
}

.fullscreen-features {
    position: relative;
}

.preset-settings {
    padding: 25px 0;
    border-top: 1px solid #d8d8d8;
}

.preset-screens {
    margin-top: 7%;
    display: grid;
    grid-template-columns: 33% 33%;
    justify-content: space-around;
}

.preset-screens>div {
    margin-bottom: 20%;
}

.preset-screens>div>.close-btn {
    width: 25px;
    height: 25px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    position: absolute;
    right: -12px;
    top: -12px;
    border-radius: 50%;
}

.preset-screens>div>.close-btn>img {}

.placeholder-no {
    margin-left: 32px;
}

.deleteable {
    position: relative;
}

.add-preset {
    width: 113px;
    height: 66px;
    margin-bottom: 7px;
    border-radius: 12px;
    border: solid 2px rgba(151, 151, 151, 0.5);
}

.add-preset>img {
    width: 26px !important;
    height: 27px !important;
    margin: 18px 41px !important;
}

.preset-screens>div>p {
    margin: 0 0 0 5px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #272727;
    text-align: center;
}

.preset-screens>div>img {
    height: 67px;
    margin: 0 0 10% 0;
    border-radius: 12px;
}

.preset-delete-popup>.popup-text {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.39px;
    text-align: center;
    color: #272727;
}

.preset-delete-popup>h3 {
    margin-bottom: 10%;
}

.preset-delete-popup>img {
    width: 185px;
    height: 113px;
    margin-bottom: 10%;
    border-radius: 15px;
}

.preset-delete-popup>div {
    width: 70%;
    margin: 4% auto 3%;
}

.preset-grid {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 24% 24% 24%;
    /* grid-row-gap: 28%; */
    justify-content: space-evenly;
}

.preset-grid>div {
    position: relative;
    margin-bottom: 15%;
}

.preset-grid>div>.close-btn {
    width: 25px;
    height: 25px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    position: absolute;
    right: -12px;
    top: -12px;
    border-radius: 50%;
}

.preset-grid>div>img {
    height: 67px;
    margin: 0 0 10% 0;
    border-radius: 12px;
}

.preset-screens>.bordered>img,
.preset-grid>.bordered>img {
    border: 5px solid #000;
}

.preset-grid>.add-preset>img {
    left: 0px;
}

.fullscreen-preset-popup .placeholder-no {
    bottom: -37px;
    color: #999;
}

.fullscreen-preset-popup>.preset-grid>div>p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #999999;
}

.fullscreen-preset-popup>p>img {
    width: 22px;
    position: absolute;
    top: 17px;
    right: 55px;
}

.fullscreen-preset-popup>.preset-grid .placeholder-no {
    margin-left: 13px !important;
    margin-top: 11px;
}

.mb-t-offset {
    margin-top: 20%;
}

.row-gap {
    grid-row-gap: 28% !important;
}

.firmware-wrapper>div>p {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: center;
    color: #999999;
}

.firmware-update-popup {
    padding: 45px 30px;
}

.firmware-update-popup>.text-holder>h4 {
    font-size: 18px;
}

.updating-firmware {
    padding-top: 12%;
}

.updating-firmware>img {
    margin-bottom: 140px;
}

.firmware-release-notes {
    width: 80%;
    margin-left: 12%;
    text-align: left;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    color: #999999;
}

.new-update-notifier {
    right: 70px !important;
}

.storage-selection-tabs>.storage-tab {
    display: inline-block;
    padding: 8px 20px;
    border-radius: 30.8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    cursor: pointer;
}

.playback-overlay>div>.storage-arc.live-view {
    width: 65%;
    margin: 0% auto;
    position: relative;
    top: 115px;
}

.storage-indicator.live-view {
    display: inline-block;
    position: relative;
    bottom: 115px;
    left: 1%;
}

.storage-selection-tabs>.storage-tab.disabled {
    color: #999;
    background-color: #e8e8e8;
}

.storage-selection-tabs>.storage-tab.active.disabled {
    font-weight: normal;
}

.storage-selection-tabs>.storage-tab:first-of-type {
    margin-right: 10px;
}

.storage-selection-tabs {
    justify-self: flex-end;
    align-self: center;
}

.playback-date-input {
    position: relative;
    /* top: 15px; */
}

.storage-selection-tabs>.active {
    background-color: #d6e9ff;
    color: #7bb0f1;
    font-weight: bold;
}

.pg-title {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.51px;
    color: #272727;
}

.profile-snap {
    background-color: #d6e9ff !important;
    text-align: center;
    color: #000;
    padding-top: 5px;
    font-weight: 500;
}

.playbacklist-li {
    padding: 2% 0;
    display: grid;
    grid-template-columns: 20% 75% 5%;
    border-bottom: 2px solid #d8d8d8;
    cursor: pointer;
}

.playbacklist-li>button {
    display: block;
    width: 110px;
    border: 0px;
    float: left;
    margin-left: 25px;
    /* margin-bottom: 15px; */
    border-radius: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 67px;
    position: relative;
    overflow: hidden;
}

.playbacklist-li>button:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.05)));
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.05));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.05));
}

.playbacklist-li>img {
    border-radius: 10px;
    margin-left: 10%;
}

.playbacklist-li>span>img {
    position: relative;
    top: 25px;
    width: 29px;
    height: 29px;
}

.tabs-overhead-menu {
    width: 100%;
    display: grid;
    grid-template-columns: 95% 5%;
}

.playback-overlay {
    display: grid;
    grid-template-columns: 50% 50%;
}

.playback-overlay>img {
    margin: 65px auto;
}

.playback-overlay>div {
    margin-bottom: 70px;
    align-self: end;
    text-align: center;
    z-index: 99;
}

.playback-overlay>.live-view {
    align-self: center;
    margin-bottom: 0px;
}

.playback-overlay>.live-view>h3 {
    margin-bottom: 15px;
}

.playback-overlay>.live-view>p {
    margin-bottom: 25px;
}

.playback-overlay>div>h3 {
    font-size: 24px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.51px;
    color: #272727;
}

.playback-overlay>div>p {
    margin-top: 15px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: center;
    color: #999999;
}

.no-sdcard-banner {
    margin-top: 5%;
    text-align: center;
}

.no-sdcard-banner>h3 {
    margin-top: 5%;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.51px;
    color: #272727;
}

.no-sdcard-banner>p {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: center;
    color: #999999;
}

.storage-main {
    margin: 165px 0px 50px 0px;
    max-width: 90%;
}

.trial_tag {
    display: inline-block;
    position: absolute;
    right: 4px;
    top: 5px;
    font-size: 11px;
    background-color: #7bb0f1;
    padding: 2px 10px 2px 10px;
    border-radius: 50px;
    line-height: 1;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1.1px;
}


/* FAQ STYLES */

.faq-cta-panel {
    display: grid;
    grid-template-columns: 40% 40%;
    grid-column-gap: 15px;
    width: 80%;
    align-items: center;
}

.faq-cta-panel .cta {
    width: 246px;
    height: 72px;
    display: grid;
    grid-template-columns: 30% 70%;
    border-radius: 12px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    justify-items: center;
    align-items: center;
}

.faq-cta-panel .cta>span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    color: #999999;
    justify-self: baseline;
}

.faq-search-panel {
    width: 100%;
    display: grid;
    padding: 5px;
    grid-template-columns: 7% 88% 5%;
    background-color: #f5f5f5;
    border-radius: 24px;
    align-items: center;
    cursor: pointer;
}

.faq-search-panel>img {
    justify-self: center;
}

.faq-search-panel>input {
    font-size: 16px;
    outline: none;
    background: transparent;
    border: none;
}

.faq-category-panel>div {
    cursor: pointer;
}

.faq-question {
    cursor: pointer;
}

.faq-answer {
    margin-bottom: 25px;
}

.faq-answer.on {
    display: block;
}

.faq-answer.off {
    display: none;
}


/* ==== Adding Subscription CSS ====  */

.display-plan-card {
    width: 96%;
    /* height: 200px; */
    border-radius: 20px;
    border: solid 4px #7bb0f1;
    background-color: #ffffff;
    margin-left: 5px;
    padding: 4% 0;
}

.display-plan-card-expired {
    width: 96%;
    /* height: 200px; */
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    margin-left: 5px;
}

.display-plan-card-expired>div {
    display: grid;
    grid-template-columns: 28% 70%;
    padding: 4% 4%;
    justify-content: center;
    align-items: center;
}

.display-plan-card>div {
    display: grid;
    grid-template-columns: 28% 70%;
    padding: 0% 4%;
    justify-content: center;
    align-items: center;
}

.btn-base-grid {
    display: grid;
    grid-template-columns: 40% 50%;
}


/* .btn-base-grid>div{
    position: relative;
}

.btn-base-grid>div>span{    
    position: absolute;
    top: 0;
    bottom: 0;
    margin-bottom: 15px;
}

.btn-base-grid>div>button{    
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin-bottom: 15px;
} */

.plan-illustration-image {
    width: 85%;
    padding: 0px 15px;
}

.plan-content-holder {
    /* position: relative; */
    /* height: 100%; */
    /* margin-top: 20px; */
}

.plan-content-holder>.plan-cta-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.plan-content-holder>div>.plan-main-btn {
    /* position: absolute; */
    /* right: 35px; */
    /* bottom: 25px; */
}


/* ==== temp btns qty 2 ==== */

.plan-content-holder>div>.plan-main-btn-02 {
    /* position: absolute;
        right: 35px;
        bottom: 80px; */
    position: absolute;
    left: 200px;
}

.plan-content-holder>div>.plan-main-btn-03 {
    /* position: absolute;
        right: 35px;
        bottom: 135px; */
    position: absolute;
    left: 10px;
}

.plan-content-holder>div>.plan-learn-more-btn {
    /* position: absolute;
        bottom: 25px; */
    font-size: 16px;
    color: #7bb0f1;
    font-weight: bold;
}

.active-plan-desc-name {
    font-size: 16px;
    font-weight: 600;
    color: #272727;
}

.active-plan-desc-days {
    font-size: 24px;
    font-weight: bold;
    color: #7bb0f1;
}

.expired-plan-desc-days {
    font-size: 24px;
    font-weight: bold;
    color: #9b9b9b;
}

.active-plan-desc-validity {
    font-size: 14px;
    color: #999999;
}

.selected-plan>.active-plan-desc-validity {
    color: #fff;
}

.selected-plan .btn.btn-info {
    background-color: #fff;
    color: #000 !important;
}

.explore-plans-text {
    font-size: 16px;
    margin: 25px 0px;
    text-align: center;
    font-weight: bold;
    color: #7bb0f1;
}

.explore-plans-text-disabled {
    color: #999999;
    cursor: not-allowed;
}

.activate-trial-view-header {
    font-size: 24px;
    color: #272727;
    margin-top: 25px;
}

.activate-trial-view-body {
    margin-top: 40px;
    text-align: center;
}

.activate-trial-view-body>img {
    width: 40%;
}

.activate-trial-view-body>div {
    margin-top: 40px;
    font-size: 16px;
    /* font-weight: 600; */
    color: #000000;
}

.minor-detail-text-block {
    margin-top: 12px;
    font-size: 12px;
    color: #999999;
}


/* ==== billing form === */

.billing-card-container {
    width: 96%;
    margin: 2%;
    background-color: #ffffff;
    /* border: 1px solid #eee; */
    padding: 15px;
    padding-top: 25px;
    border-radius: 12px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
}

.billing-card-container>div>ul.listItems {
    padding-bottom: 5px;
    border-bottom: 4px solid #f5f5f5;
}

.billing-card-container>.dualGrid {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
}

.billing-card-container>.billing-card-header {
    font-size: 18px;
    font-weight: bold;
    color: #272727;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.billing-card-container>.billing-condition-check {
    font-size: 18px;
    margin-top: 45px;
    margin-left: 2%;
}

.plan-payment-summary-head {
    font-size: 18px;
    font-weight: bold;
    color: #272727;
}

.plan-payment-summary-content {
    font-size: 16px;
    color: #272727;
}

.plan-payment-summary-promo {
    font-size: 18px;
    font-weight: bold;
    color: #999999;
}

.selected-plan>.plan-payment-summary-promo {
    color: #fff;
    font-size: 26px !important;
}

.single-plan-price-box {
    padding: 15.5px 0px 15.5px 0px;
    border-radius: 20px;
    border: solid 5.5px #d7e7f7;
}

.single-plan-image {
    width: 256px !important;
}

.single-plan-price-label {
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.51px;
    text-align: center;
    color: #7bb0f1;
}

.plan-payment-summary-total-cost {
    font-size: 36px;
    color: #040404;
}

.info-bubble {
    width: 96%;
    padding: 21px 23px;
    margin: 2%;
    background-color: #fff4da;
    border-radius: 20px;
}

.subscription-bubble {
    width: 96%;
    padding: 21px 23px;
    margin: 2%;
    background-color: #d7e7f7;
    border-radius: 20px;
}

.all-plans {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    column-gap: 2%;
}

.all-plans>div {
    border-radius: 12px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    text-align: center;
    padding: 0px 30px;
}

.all-plans>.selected-plan {
    border: solid 4px #7bb0f1;
    background-color: #7bb0f1;
}

.all-plans>div>img {
    width: 45%;
    margin-top: 30px;
}


/* ==== Promo styles ==== */

.promo-grid {
    display: grid;
    grid-template-columns: 80% 20%;
}

.promo-text-title {
    font-size: 16px;
    font-weight: bold;
    color: #9fc576;
}

.promo-text-desc {
    font-size: 16px;
    columns: #272727;
}

.promo-selected-btn {
    text-align: center;
}

.promo-unselected-btn {
    width: 23px;
    height: 23px;
    border-radius: 25px;
    border: solid 2px #999999;
}

.promo-selector-central-margin {
    margin: auto auto;
}

.promo-selected-img {
    width: 23px;
    height: 23px;
}

.scrollable-promo-options {
    max-height: 450px;
    overflow-y: auto;
}

.promo-selected-details-text {
    margin: 7px 0px;
    font-size: 12px;
    color: #999999;
    width: 400px;
    height: auto;
}


/* ==== CSS for payment history and payment details ==== */

.payment-history-table-alignment {
    padding: 10px 30px;
}

.payment-history-table {
    display: grid;
    grid-template-columns: 28% 28% 28% 10%;
    column-gap: 2%;
}

.payment-history-table>div {
    font-size: 16px;
    color: #272727;
}

.payment-history-view-details {
    font-size: 16px;
    font-weight: bold;
    color: #7bb0f1 !important;
}

.payment-detail-extended-alignment {
    padding: 15px 30px!important;
}

.payment-detail-grid-01 {
    display: grid;
    grid-template-columns: 25% 73%;
    column-gap: 2%;
}

.payment-detail-grid-02 {
    display: grid;
    grid-template-columns: 5% 35% 35% 15%;
    column-gap: 3%;
}

.payment-detail-grid-03 {
    display: grid;
    grid-template-columns: 80% 15%;
    column-gap: 4%;
}

.input-width-40 {
    width: 40%;
}

.light-note {
    font-size: 14px;
    color: #999;
    text-align: center;
}


/* Auto debit styles */

.auto-debit-trial {
    width: 96%;
    margin: 2%;
    padding: 30px 10px;
    border-radius: 12px;
    align-items: center;
    text-align: center;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 12%);
}

.auto-debit-trial h2 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.51px;
    color: #272727;
}

.free-trial-illustration>hr {
    width: 67%;
    border: 5px solid #ddd;
    border-radius: 25px;
}

.auto-debit-trial>.free-trial-illustration {
    width: 100%;
}

.free-trial-illustration>.illustration {
    display: flex;
    position: relative;
    top: -28px;
}

.free-trial-illustration>.illustration>div {
    flex: 1;
}

.free-trial-illustration>.illustration>div>p {
    text-align: center;
    padding: 0 20%;
    font-size: 14px;
    color: #272727;
}

.free-trial-illustration>.illustration>div>h4 {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 900;
    color: #272727;
    text-align: center;
}

.auto-debit-paid {
    display: flex;
    width: 96%;
    margin: 2%;
    padding: 30px 10px;
    border-radius: 12px;
    align-items: center;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 12%);
}

.auto-debit-paid.blurred {
    filter: opacity(0.5);
}

.auto-debit-paid>.auto-debit-info {
    flex: 3;
}

.auto-debit-paid>.auto-debit-info>h4 {
    padding-right: 12%;
    font-size: 18px;
    font-weight: 900;
    color: #272727;
}

.auto-debit-paid>.auto-debit-info>p {
    padding-right: 14%;
    font-size: 17px;
    font-weight: 500;
}

.auto-debit-paid>.auto-debit-illustration {
    flex: 2;
    align-self: center;
    text-align: center;
}

.auto-debit-paid>.auto-debit-illustration>img {
    width: 80%;
}

.auto-debit-checkbox-txt {
    align-self: center;
    font-weight: 600;
    font-size: 17px;
    color: #383838;
}


/* Auto debit styles end */

.centered {
    justify-content: center;
}


/* New card styles */

.pending-transactions-note {
    width: 100%;
    padding: 20px;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.39;
    letter-spacing: 0.39px;
    text-align: left;
    border-radius: 20px;
    background-color: #fff4da;
    cursor: pointer;
}

.extra-pending-note {
    margin: 15px 0 0 0;
    padding: 5px;
    font-size: 16px;
    color: #999;
}

.trial-subscription-card,
.first-plan-subscription-card {
    width: 98%;
    margin-left: 4px;
    border-radius: 20px;
    background-color: #d7e7f7;
}

.trial-subscription-card .trial-header,
.trial-subscription-card .pending-card-header {
    display: flex;
    padding: 30px 0 30px 20px;
}

.trial-subscription-card .trial-header img {
    width: 350px;
}

.trial-subscription-card .pending-card-header img {
    width: 240px;
}

.trial-body {
    padding: 30px 0 0px 30px;
}

.first-plan-subscription-card .first-plan-header {
    display: flex;
    justify-content: center;
    padding: 3% 0;
}

.first-plan-subscription-card .first-plan-header>img {
    width: 190px;
}

.first-plan-subscription-card .first-plan-body {
    text-align: center;
}

.first-plan-subscription-card .first-plan-body>h2 {
    font-size: 30px;
    line-height: 1.07;
    font-weight: bold;
    color: #7bb0f1;
}

.first-plan-subscription-card .first-plan-body>p {
    margin-top: 30px;
    font-size: 18px;
    line-height: 1.4;
    font-weight: normal;
    color: #000;
}

.pending-body {
    padding: 30px 0 0px 30px;
}

.pending-body>span {
    width: 90%;
    font-size: 16px;
    font-weight: 500;
    color: #999;
    line-height: 1.3;
}

.pending-body p {
    width: 90%;
    font-size: 16px;
    font-weight: 500;
}

.pending-body p:first-of-type {
    font-size: 18px;
    font-weight: 700;
}

.pending-body p:last-of-type {
    margin-bottom: 35px;
}

.trial-body p {
    font-size: 18px;
    letter-spacing: 0.39px;
    color: #000;
}

.subscription-card-pack {
    width: 98%;
    margin-left: 4px;
    border-radius: 20px;
    background-color: #d7e7f7;
}

.card-middle-layer {
    padding-bottom: 40px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    z-index: 10;
    min-height: 400px;
}

.subscription-top-card {
    display: flex;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.subscription-top-card.mandate-changed {
    box-shadow: none;
}

.subscription-top-card.blue {
    background-color: #7bb0f1;
}

.subscription-top-card.red {
    background-color: #fc7866;
}

.subscription-top-card.dark-grey {
    background-color: #999;
}

.subscription-top-card.light-grey {
    background-color: #d7d7d7;
}

.subscription-top-card>img {
    width: 210px;
    position: relative;
    top: 45px;
    left: 40px;
}

.mandate-changed-subscription-card {
    width: 98%;
    margin-left: 1%;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.mandate-changed-footer {
    display: flex;
    margin: 40px 0 0;
}

.mandate-changed-footer>p {
    flex: 1;
    font-size: 16px;
}

.mandate-changed-footer>.main-cta-section {
    padding: 0px;
    display: flex;
    flex: 1;
}

.mandate-changed-info-section {
    margin-top: 10%;
    padding: 10px 40px;
}

.mandate-changed-info-section>p {
    font-size: 18px;
}

.mandate-changed-info-section span {
    font-size: 16px;
    font-weight: 500;
}

.mandate-changed-info-section>.mandate-detail-section {
    width: 100%;
    margin-top: 25px;
}

.spacer {
    height: 10px;
}

.mandate-changed-info-section>.mandate-detail-section>table>tr>td {
    font-size: 16px;
}

.mandate-changed-info-section>.mandate-detail-section>table>tr>td:last-child {
    font-weight: 900;
    padding-left: 20px;
}

.plan-details-section {
    flex: 2 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    padding: 0 40px 0px 0;
}

.plan-details-section p {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
}

.mandate-changed p {
    font-size: 24px;
}

.plan-details-section span {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: 0.34px;
    text-align: right;
    color: #fff;
}

.plan-details-section.primary {
    flex: 2 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    padding: 0 40px 20px 0;
}

.plan-details-section.danger p {
    font-size: 24px !important;
    line-height: 1.3;
    margin-bottom: 0px;
}

.primary,
.primary p {
    color: #7bb0f1 !important;
}

.primary span {
    color: #7bb0f1;
}

.danger,
.danger p {
    color: #fc7866 !important;
}

.danger span {
    color: #fc7866;
}

.payment-info-section {
    display: flex;
    flex-direction: column;
    /* min-height: 200px; */
    justify-content: center;
    margin-top: 75px;
    padding: 0 0 0px 40px;
    color: #000
}

.main-cta-section {
    display: flex;
    justify-content: flex-end;
    padding: 25px 40px 0px 0;
}

.disabled-bordered-cta {
    color: #ddd !important;
    outline: none !important;
    box-shadow: none !important;
    border: 3px solid #ddd;
    padding: 8px 30px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 900;
}

.enabled-bordered-cta {
    color: #7bb0f1 !important;
    outline: none !important;
    box-shadow: none !important;
    border: 3px solid #c3d8f2;
    padding: 8px 30px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.5px;
    cursor: pointer;
}

.additional-notes-section {
    padding: 20px 40px 0px 40px;
}

.subscription-card-footer {
    display: flex;
    padding: 30px 40px 25px 40px;
}

.subscription-card-footer.trial {
    display: flex;
    padding: 30px 40px 25px 40px;
    align-items: center;
    justify-content: space-between;
}

.subscription-footer-cta {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.payment-deadline-section {
    display: flex;
    margin: 60px 40px 0 40px;
    padding: 15px 12px;
    align-items: flex-end;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    border: solid 4px #fc7866;
    background-color: rgba(252, 120, 102, 0);
}

.payment-deadline-section img {
    width: 130px;
    height: 135px;
}

.payment-deadline-section>.deadline-details>p:last-of-type {
    margin-bottom: 0px;
}

.payment-deadline-section>.deadline-details {
    padding-top: 10px;
    padding-left: 10px;
}

.payment-deadline-section .imp-head {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.39px;
    text-align: center;
    color: #272727;
}

.update-mandate-wrapper {
    /* background-color: #d7e7f76e; */
    width: 95%;
    margin: 25px auto 0;
    padding: 10px 0;
    border-radius: 16px;
    box-shadow: 0 1px 12px -5px rgb(0 0 0 / 50%);
}

.height-transition {
    transition: height 2s;
}

.update-mandate-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3%;
}

.update-mandate-section>div {
    flex: 1;
    text-align: center;
}

.update-mandate-head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.update-mandate-head>h1 {
    width: 92%;
    display: inline-block;
    margin: 0 0 0 5px;
}

.update-option-button {
    padding: 12px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 12%);
    background-color: #fff;
    height: 175px;
    width: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
}

.update-option-button>p {
    margin: 10px 0 0 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.02px;
    text-align: center;
    color: #7bb0f1;
}

.update-option-button>img {
    width: 65%;
}


/* End of New card styles */


/* ====Card Details ==== */

.card-padding {
    padding: 20px 30px;
}

.plan-day-title {
    margin: 25px 0;
    font-size: 21px;
    font-weight: bold;
    padding-bottom: 25px;
    border-bottom: 3px solid #d0d0d0;
    color: #7bb0f1;
}

.selected-plan>.plan-day-title {
    color: #fff;
}

.extra-border-bottom {
    padding-bottom: 25px;
    border-bottom: 3px solid #d0d0d0;
}


/* ==== Disabled device ==== */

.disabled-device {
    color: #dedede;
    cursor: not-allowed;
}

.enabled-device {
    color: #272727 !important;
}

.group-name {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #000 !important;
    margin-right: 10px;
}


/* ==== styles for Trial payment success/failure page ==== */

.illustration-image-success {
    width: 50%;
}

.selected-highlighted-text {
    font-size: 24px;
    font-weight: 600;
    color: #272727;
}

.simple-text-01 {
    font-size: 16px;
    color: #999999;
    margin-bottom: 10px;
}

.simple-text-02 {
    font-size: 16px;
    color: #999999;
    margin-top: 10px;
}


/* Billdesk redirection screen styles */

.bdsk-wrapper {
    text-align: center;
    width: 100%;
    margin-top: 2%;
}

.bdsk-body {
    position: relative;
}

.bdsk-body>img {
    width: 25%;
}

.bdsk-wrapper .info-section {
    width: 80%;
    margin: 0 auto;
}

.bdsk-wrapper .bubble-text {
    background-color: #d7e7f7;
    width: 40%;
    margin: 0 auto 2%;
    padding: 1% 2%;
    border-radius: 20px;
    font-size: 16px;
}

.bdsk-wrapper .bubble-text>p {
    font-size: 16px;
    margin-bottom: 0px;
}

.bdsk-wrapper .info-section .light-text {
    font-size: 16px;
}

.bdsk-wrapper .dark-text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.02px;
    text-align: center;
    color: #272727;
}

.bdsk-wrapper>.head-section {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    padding: 2% 0;
    border-radius: 18px;
    box-shadow: 0 0 6px 0 rgba(91, 91, 91, 0.29);
    background-color: #fff;
}

.bdsk-wrapper>.head-section>h2 {
    font-size: 24px;
    letter-spacing: 0.51px;
    color: #272727;
    margin-bottom: -5px;
    align-self: center;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 800;
}

.bdsk-wrapper>.head-section>img {
    width: 260px;
}

.bdsk-wrapper .bubble-text.text-top {
    width: 20vw;
    font-weight: 900;
    position: absolute;
    top: 40%;
    left: 32%;
}

.bdsk-wrapper .bubble-text.text-bottom {
    font-weight: 900;
}

@media (max-width: 1600px) {
    .recording-status-snip {
        width: 37%;
        padding: 5px 0 5px 40px;
        left: 34%;
    }
    a.action-btn>span {
        font-size: 13px;
    }
    .snapshot>.actionbox {
        width: 100%;
        top: 10px;
        margin-top: 0px;
    }
    .storage-arc {
        width: 55%;
        margin: 0 auto;
        padding-top: 5%;
    }
    .storage-indicator {
        bottom: 295px;
        left: 43%;
    }
    .storage-indicator.tab-mode {
        bottom: 275px;
        left: 35%;
    }
    .storage-arc-text {
        bottom: 225px;
    }
    .storage-main.device-page-container .error-player button {
        /* margin: 0 0 0 34%; */
        position: absolute;
    }
    .profile-menu .dropdown-menu {
        right: -85px;
    }
}

@media (min-width: 1601px) {
    a.action-btn .ico {
        /* position: absolute; */
        /* top: 13px; */
        left: 12px;
        width: 30px;
    }
    .main {
        margin: 120px 350px 100px 250px;
        max-width: 1210px !important;
    }
    .main>.camera-single {
        height: 680px;
    }
    .main .error-player button {
        /* margin: 0 39%; */
    }
    .device-page-container>.camera-single {
        height: 575px;
    }
}

@media (min-width: 1401px) {
    .recording-status-snip {
        left: 33% !important;
    }
    .aside {
        width: 375px;
    }
    .welcome-sidebar {
        width: 346px !important;
    }
    .camera-actions-sm>.action-btn {
        width: 118px !important;
    }
    .members-area>.main {
        margin: 120px 350px 100px 250px;
        max-width: 1240px !important;
    }
}

@media (max-height: 800px) {
    .snapshot>.closebox {
        top: 25px;
    }
    .saved-toast {
        top: 1%;
    }
    .error-player img {
        width: 15%;
        /* margin: 10% 42.5% 5%; */
    }
}

@media (max-width: 1400px) {
    .recording-status-snip {
        width: 41%;
        padding: 5px 0 5px 40px;
        left: 34%;
    }
    .groups-notification>li>h4 {
        font-size: 13px;
    }
    .groups-notification>li>.notes {
        font-size: 11px;
    }
    .notification-status-online,
    .notification-status-offline {
        padding: 4px 8px;
        font-size: 10px;
        line-height: 1;
        margin-bottom: 4px;
        margin-top: 5px;
    }
    .three-col {
        width: 115px !important;
    }
    .camera-actions-sm>.action-btn {
        width: 118px !important;
    }
    .camera-actions-sm.extra>.action-btn {
        width: 120px !important;
    }
    .camera-card {
        display: block;
        width: 330px;
        border: 0px;
        float: left;
        margin-right: 10px;
        margin-bottom: 30px;
        border-radius: 15px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        min-height: 180px;
        position: relative;
        overflow: hidden;
    }
    .storage-indicator {
        left: 42%;
    }
    .storage-recordings>.recording-li>img {
        width: 120px;
        height: 75px;
    }
    .storage-recordings>.recording-li {
        grid-template-columns: 12% 35% 10% 25%;
        padding: 10px 0;
    }
    .recording-li h4 {
        margin: 4px 0 2px 0;
        font-size: 16px;
    }
    .recording-li .small-notes p {
        margin-bottom: 0px;
    }
    .storage-main {
        margin: 20% 165px 0px 50px 0px !important;
        width: 95%;
    }
    .checkmarker .checkmark {
        font-size: 15px;
    }
    .preset-btn {
        width: 55.5%;
        height: 164px;
    }
    .tab-loader {
        top: 10% !important;
    }
    .bdsk-wrapper .bubble-text.text-top {
        width: 25vw;
        font-weight: 900;
        position: absolute;
        top: 37%;
        left: 25%;
    }
}

@media (max-width: 1280px) {
    .camera-actions-sm>.action-btn {
        width: 103px !important;
    }
    .error-player img {
        /* margin: 7% 0 6% 43%; */
    }
    .error-player button {
        /* margin: 0 0 0 35%; */
    }
    .device-page-container .error-player button {
        /* margin: 0 0 0 33%; */
        position: absolute;
    }
    .storage-main.device-page-container .error-player button {
        /* margin: 0 0 0 32%; */
    }
    .storage-arc {
        width: 60%;
        margin: 0 auto;
        padding-top: 5%;
    }
    .storage-indicator.tab-mode {
        bottom: 245px;
        left: 33%;
    }
    .device-page-container .camera-single {
        height: 443px;
    }
    .camera-single {
        height: 503px;
    }
    .storage-main {
        margin: 165px 0px 50px 0px;
        max-width: 90%;
    }
    /* .force-centered {
            bottom: 65px;
            left: 85px;
        } */
    .playback-filter-col {
        grid-template-columns: 33% 54% 12%;
    }
    .playback-filter-btn>div>input,
    .playback-filter-btn>div>span {
        margin-left: 10px;
    }
    .bdsk-wrapper .bubble-text {
        width: 50%;
    }
    .bdsk-wrapper .bubble-text.text-top {
        width: 25vw;
        font-weight: 900;
        position: absolute;
        top: 37%;
        left: 32%;
    }
}

@media(min-width: 1281px) and (max-width: 1441px) {
    .profile-main {
        margin: 165px 30px 50px 0px !important;
    }
    .no-pad {
        padding: 0px !important;
    }
    .dualGrid>.styleddropdown>.rw-widget>.rw-popup-container {
        width: 383px;
    }
    /* .storage-main {
        margin: 165px 0px 50px 0px;
        max-width: 80%;
    } */
    .device-page-container>.camera-single {
        height: 530px;
    }
    .playback-filter-btn>div>input,
    .playback-filter-btn>div>span {
        margin-left: 10px;
    }
}

@media (max-width: 1200px) {
    .camera-single {
        min-height: 407px;
    }
    .storage-indicator {
        bottom: 220px;
        left: 39%;
    }
    .storage-recordings>.recording-li>img {
        width: 100px;
        height: 65px;
    }
    .storage-recordings>.recording-li {
        grid-template-columns: 15% 35% 10% 25%;
        padding: 10px 0;
    }
    .recording-li h4 {
        margin: 4px 0 2px 0;
        font-size: 16px;
    }
    .recording-li .small-notes p {
        margin-bottom: 0px;
    }
    .dualGrid>.styleddropdown>.rw-widget>.rw-popup-container {
        width: 114%;
    }
    /* .bdsk-body>img {
        width: 25%;
    } */
}

@media (max-width: 991px) {
    .free-trial-illustration>.illustration {
        flex-direction: column;
        top: 0;
    }
    .free-trial-illustration>.illustration>div {
        margin-bottom: 15px;
    }
    .form-panel .social-buttons .btn.btn-social {
        line-height: 45px;
        text-align: left;
        font-size: 14px;
    }
    .top-bar {
        padding: 30px 15px;
    }
    .top-bar .logo img {
        max-height: 50px;
    }
    .form-panel .social-buttons .btn.btn-social img {
        margin-top: 3px;
        margin-right: 8px;
    }
    .form-panel .social-buttons .btn.btn-social br {
        display: block!important;
    }
    .hide-on-mobile {
        display: none !important;
    }
    .show-on-mobile {
        display: block;
    }
    .non-mobile-view {
        display: block;
        padding: 0 10px;
    }
    .height-auto {
        height: auto !important;
    }
    .nav {
        width: 100%;
        overflow-y: auto;
        flex-wrap: nowrap !important;
        white-space: nowrap;
    }
    ul.nav.nav-pills li {
        display: inline !important;
    }
    .display-plan-card {
        padding: 15px 0px;
        height: auto;
        margin-bottom: 10px;
    }
    .display-plan-card>div {
        grid-template-columns: 100%;
    }
    .display-plan-card-expired>div {
        grid-template-columns: 100%
    }
    .plan-content-holder {
        margin-top: 0px;
    }
    .plan-content-holder>div>.plan-main-btn {
        /* position: relative;
            left: 95px;
            right: 0;
            bottom: 10px; */
    }
    .plan-learn-more-btn>div {
        position: relative;
        top: 5px;
    }
    .activate-trial-view-header {
        margin-bottom: 20px !important;
        font-size: 20px;
    }
    .all-plans {
        margin-bottom: 18%;
        grid-template-columns: 100%;
        grid-row-gap: 2%;
    }
    .all-plans>div {
        padding: 0px 30px;
    }
    .plan-day-title {
        margin: 10px 0;
        font-size: 35px;
        padding-bottom: 5px
    }
    .active-plan-desc-validity {
        font-size: 15px;
    }
    .extra-border-bottom {
        padding-bottom: 8px;
    }
    .plan-payment-summary-promo {
        margin-top: 15px !important;
        font-size: 16px !important;
    }
    .explore-plans-text {
        margin: 5px 0px;
    }
    .plan-payment-summary-head {
        font-size: 16px;
    }
    .heavy {
        position: relative;
        top: 12px;
    }
    .pullup {
        position: relative;
        bottom: 1px;
    }
    .billing-card-container {
        margin: 2% 2% 5% 2%;
        padding: 20px 15px 1px 15px;
    }
    .info-bubble {
        margin: 2% 2% 5% 2%;
    }
    .popup-overlay .popup-box {
        position: absolute;
        height: 100%;
        overflow: auto;
    }
    .plan-payment-summary-total-cost {
        font-size: 30px;
    }
    header .logo img {
        max-height: 45px;
    }
    .logout-btn {
        float: right;
        position: relative;
        top: 15px;
        font-size: 16px;
        color: #7bb0f1;
        font-weight: 800;
        letter-spacing: 1.1px;
    }
    .special {
        position: relative;
        bottom: 0px;
    }
    .form-panel .backArrow {
        margin-bottom: 10px;
    }
    .billing-card-container>.dualGrid {
        grid-template-columns: 100%;
    }
    .tab-loader {
        left: 0px !important;
        top: 0px !important;
    }
    /* Subscription cards */
    .trial-subscription-card .trial-header {
        padding: 10px 0 0px 15px;
    }
    .subscription-card-footer.trial {
        align-items: initial;
        text-align: center;
        flex-direction: column-reverse;
    }
    .footer-message.primary {
        margin-top: 10px !important;
    }
    .plan-details-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 30px 15px 30px 15px;
    }
    .plan-details-section.primary {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 30px 15px 0px 15px;
    }
    .plan-details-section p {
        font-size: 20px;
        text-align: left;
    }
    .plan-details-section span {
        font-size: 14px;
        text-align: left;
    }
    .payment-info-section {
        margin-top: 20px;
        padding: 0 15px 0px 15px;
    }
    .additional-notes-section {
        padding: 10px 20px 0px 20px;
    }
    .subscription-card-footer {
        flex-direction: column;
        padding: 20px 20px 20px 20px;
    }
    .card-middle-layer {
        min-height: 200px;
    }
    .main-cta-section {
        padding: 20px 20px 0px 0;
    }
    .payment-deadline-section {
        margin: 40px 10px 0 10px;
        flex-direction: column;
        align-items: center;
    }
    .bdsk-body>img {
        width: 35%;
    }
}

@media (min-width: 1000px) {
    .onboarding .form-panel {
        min-height: 529px;
    }
    .sidebar-nav ul.sidebar-nav-buttons:before {
        content: "";
        position: absolute;
        top: 0;
        right: 10px;
        width: 700px;
        height: 100%;
        border-radius: 24px;
        background-color: #FFF;
        box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.06);
    }
}

@media (min-width: 769px) {
    .form-panel .social-buttons .btn.btn-social br {
        display: none;
    }
    .main {
        margin: 120px 350px 100px 250px;
        max-width: 1024px;
    }
    .profile-main {
        margin: 165px 50px 50px 0px;
        max-width: 860px;
    }
    .main.no-sidebar {
        margin-left: 25px!important;
    }
    .main.no-aside {
        margin-right: 100px!important;
    }
    .no-sidebar>a>h4>img {
        position: relative;
        bottom: 2px;
    }
    .shrinked_50 {
        width: 50%;
    }
    .shrinked_65 {
        width: 65%;
    }
    .shrinked_85 {
        width: 85%;
    }
    .sidebar-nav {
        width: 100%;
        /* height: 130vh; */
        padding: 140px 0px 20px 0px;
    }
    .first-plan-subscription-card .first-plan-body>p {
        width: 90%;
        margin: 0 auto;
    }
    .first-plan-subscription-card .first-plan-body>h2 {
        margin-bottom: 20px;
    }
    .auto-debit-paid>.auto-debit-info>h4 {
        padding-right: 0;
    }
    .auto-debit-paid>.auto-debit-info>p {
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .popup-txt-p {
        width: 100%;
    }
    .popup_logo {
        width: 65% !important;
        margin-bottom: 10px !important;
    }
     ::-webkit-scrollbar {
        width: 1px;
        background: #ccc;
        border-radius: 50px;
    }
    .mobile-height {
        min-height: 30px !important;
    }
    .mobile-pad {
        padding: 35px 0 !important;
    }
    .mobile-overflow {
        max-width: 100%;
        overflow-y: auto;
    }
    .payment-detail-grid-02 {
        grid-template-columns: 10% 45% 45% 20%;
        column-gap: 5%;
    }
    .dualGrid>.styleddropdown>.rw-widget>.rw-popup-container {
        width: 113%;
    }
    .payment-history-table {
        grid-template-columns: 30% 50% 20%;
    }
    .payment-history-table-alignment {
        padding: 10px 10px;
    }
    .payment-detail-grid-01 {
        grid-template-columns: 40% 55%;
        -webkit-column-gap: 5%;
        -moz-column-gap: 5%;
        column-gap: 5%;
    }
    .payment-detail-grid-03 {
        grid-template-columns: 70% 20%;
        grid-column-gap: 10%;
        -webkit-column-gap: 10%;
        -moz-column-gap: 10%;
        column-gap: 10%;
    }
    .wrapper {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
        /* background-image: url(../images/bg01.png); */
        background-image: none;
    }
    .navbar-toggle {
        border: 0px solid #FFF;
        background-color: #B23F6E;
        margin-top: 17px;
    }
    .navbar-toggle .icon-bar {
        background-color: #FFF;
        height: 3px;
    }
    .sidebar-nav {
        width: 100%;
        height: auto;
        padding: 0px;
    }
    .aside {
        right: -450px;
    }
    .sidebar {
        left: -220px;
    }
    .main,
    .profile-main {
        padding: 75px 15px 25px 15px;
    }
    .logo {
        padding: 10px!important;
        width: 160px!important;
    }
    .auto-debit-paid {
        flex-direction: column;
    }
    .auto-debit-paid>.auto-debit-info {
        text-align: center;
    }
    .mobile-centered {
        justify-content: center;
    }
    /* Billdesk redirection screen styles */
    .bdsk-wrapper .info-section {
        width: 90%;
    }
    .bdsk-wrapper .bubble-text {
        width: 90%;
        font-size: 16px;
        padding: 10px;
    }
    .bdsk-wrapper .bubble-text>p {
        margin-bottom: 0px;
    }
    .bdsk-wrapper>.head-section {
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .bdsk-wrapper>.head-section>img {
        margin: 10px 0;
    }
    .bdsk-body>img {
        width: 45%;
    }
    .bdsk-wrapper .bubble-text.text-top {
        width: 30vh;
        font-weight: 900;
        position: absolute;
        top: 35%;
        left: 20%;
    }
}

@media (max-width: 480px) {
    .bdsk-body>img {
        width: 75%;
    }
    .pending-body {
        padding: 15px 0 0 15px;
    }
    .pending-card-header {
        padding: 0px !important;
    }
    .mandate-changed-info-section {
        margin-top: 5px;
        padding: 10px 15px;
    }
    .mandate-changed-info-section>.mandate-detail-section>table>tr>td {
        font-size: 15px;
    }
}

@media (max-width: 375px) {
    .dualGrid>.styleddropdown>.rw-widget>.rw-popup-container {
        width: 115%;
    }
    .plan-payment-summary-promo {
        font-size: 15px !important;
    }
    .pullup {
        bottom: 2px;
    }
    .pullup2 {
        position: relative;
        bottom: 2px;
    }
    .payment-history-view-details {
        font-size: 15px !important;
    }
    .btn.btn-info {
        padding: 5px 30px;
    }
    .display-plan-card {
        /* height: 220px; */
    }
    .plan-content-holder>.plan-learn-more-btn {
        bottom: 21px;
    }
    .bdsk-body>img {
        width: 80%;
    }
}

@media (max-width: 320px) {
    .btn.btn-info {
        padding: 5px 20px;
    }
    .plan-learn-more-btn>div {
        position: relative;
        top: 8px;
    }
    .bdsk-wrapper .bubble-text.text-top {
        width: 70%;
    }
    .bdsk-body>img {
        width: 95%;
    }
}
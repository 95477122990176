.individual-block {
    background-color: #fff;
    margin: 5px auto;
    padding: 15px
}

.test-01 {
    position: relative;
    height: auto;
}

.test-mid-01 {
    position: relative;
    width: 100%;
}

.test-02 {
    position: relative;
    width: 100%;
    height: 100x;
    overflow-y: hidden;
    /* // overflow-x: hidden; */
    /* background-color: #d8d8d8; */
    /* // background-color: #fff; */
    cursor: grab;
}


/* // .test-02::-webkit-scrollbar {
//     display: none;
// } */

.test-mid-03 {
    position: relative;
    /* // width: 400%; */
    height: 100px;
    /* background-color: burlywood; */
    /* // background-color: #fff;         */
    margin: 35px 0px 0px 0px;
}


/* // .test-mid-03 */

.test-03 {
    /* // position: relative;
    // width: 1100px;
    // width: 2200px;
    // width: 300%;
    // width : 100%; */
    height: 55px;
    background-color: #d7e7f7;
    /* // background-color: #fff;
    // border-radius: 25px;
    // margin: 20px 30px 0px 30px; */
    /* // ==== Changes acc % ==== */
    position: relative;
    /* // width: 75%; 
    // left: 11%;
    // right: 11%;

    // ==== final reader list ==== 
    // width : 78.125%;
    // left : 10.9375%; */
}

.test-04 {
    height: 55px;
    background-color: #7bb0f1;
    /* // ==== bottom test ====
    // margin-left: 5%;
    // width: 5%; */
}

.test-grid {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    /* // grid-template-columns: 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4% 4%;         */
}

.test-grid-01-content {
    position: absolute;
}

.line-col {
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #999999;
    justify-self: start;
}

.time-stamp {
    /* position: relative;
    // width: 1100px;
    // width: 2200px;
    // width: 300%; */
    height: 25px;
    background-color: #fff;
    /* // border-radius: 20px;
    // margin: 0px 30px;

     //==== Changes as per % ==== */
    position: absolute;
    width: 75%;
    left: 11.75%;
    top: 35px;
    right: 11.75%;
    width: 78.125%;
    left: 10.9375%;
}

.time-stamp-style {
    justify-self: center;
    font-size: 14px;
    color: #999999;
}

.time-stamp-box {
    position: absolute;
    top: 65px;
    width: 45px;
}

.time-stamp-string {
    font-size: 11px;
    /* // transform: rotate(-90deg); */
    position: relative;
    left: -50%;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10+ and Edge */
    user-select: none;
    /* Standard syntax */
}

.scrubber-bar {
    position: absolute;
    width: 2px;
    height: 50px;
    background-color: #7bb0f1;
    left: 50%;
    right: 50%;
    top: 5%;
    bottom: 8%;
    z-index: 2
}

.scrubber-panel {
    position: absolute;
    height: 110px;
    left: 50%;
    top: 1%;
    cursor: grab;
    pointer-events: none;
}

.scrubber-bar-02 {
    width: 2px;
    background-color: #000;
    height: 65%;
}

.scrubber-text {
    position: relative;
    left: -50%;
    font-size: 17px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10+ and Edge */
    user-select: none;
    /* Standard syntax */
}